import React, { useEffect } from 'react';
import { Group } from '../Types';
import { determineRequiredCaretakers } from '../utils/DaycarePlanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

interface BKRInfoModalProps {
  group: Group;
  onClose: () => void;
}

const BKRInfoModal: React.FC<BKRInfoModalProps> = ({ group, onClose }) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const formatAgeRange = (group: Group): string => {
    const nonEmptyRanges = group.ageRanges.filter(range => (group.children[range] || 0) > 0);
    if (nonEmptyRanges.length === 0) return 'N/A';
    if (nonEmptyRanges.length === 1) return `${nonEmptyRanges[0]}`;
    return `${nonEmptyRanges[0].split('-')[0]}-${nonEmptyRanges[nonEmptyRanges.length - 1].split('-')[1]}`;
  };

  const totalChildren = Object.values(group.children).reduce((sum, count) => sum + count, 0);
  const requiredCaretakers = Math.ceil(determineRequiredCaretakers(group));
  const formattedAgeRange = formatAgeRange(group);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-4 max-w-md w-full shadow-lg max-h-[90vh] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-bold mb-4 flex flex-col items-center text-center">
            <FontAwesomeIcon icon={faInfoCircle} className="text-accent mb-2" />
            Uitleg Groepsbezetting
        </h2>
        <hr className="border-t border-gray-300 my-4 w-1/4 max-w-[100px] mx-auto" />
        {totalChildren === 0 ? (
          <p className="mb-4">
            Deze groep heeft nog geen kinderen. Als er kinderen in de groep komen, zie je hier hoeveel medewerkers er nodig zijn volgens de richtlijnen van de BKR.
          </p>
        ) : (
          <p className="mb-4">
            Volgens de richtlijnen van de BKR {requiredCaretakers === 1 ? 'is' : 'zijn'} er minstens <b>{requiredCaretakers} medewerker{requiredCaretakers === 1 ? '' : 's'}</b> nodig voor deze groep van <b>{totalChildren} {totalChildren === 1 ? 'kind' : 'kinderen'}</b>. Deze groep valt in de categorie {formattedAgeRange} jaar.
          </p>
        )}
        <a 
          href="https://www.1ratio.nl/bkr/#/rekentool" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-accent hover:underline mb-4 block"
        >
          Bekijk de BKR rekentool
        </a>
        <button
          onClick={onClose}
          className="w-full bg-accent text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors"
        >
          Sluiten
        </button>
      </div>
    </div>
  );
};

export default BKRInfoModal;