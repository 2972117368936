import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { Link } from 'react-router-dom';

interface LoginProps {
  showAlert: (message: string, onConfirm: () => void) => void;
}

const Login: React.FC<LoginProps> = ({ showAlert }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const searchParams = new URLSearchParams(location.search);
      const redirectPath = searchParams.get('redirect') || '/edit';
      navigate(redirectPath);
    } catch (error) {
      showAlert('Inloggen mislukt. Controleer uw e-mailadres en wachtwoord.', () => {});
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      showAlert('Vul alstublieft uw e-mailadres in.', () => {});
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      showAlert('Er is een wachtwoord reset link verzonden naar uw e-mailadres.', () => {});
    } catch (error) {
      showAlert('Er is een fout opgetreden bij het verzenden van de wachtwoord reset link.', () => {});
    }
  };

  return (
    <div className="flex items-center justify-center py-20 bg-main-bg">
      <div className="bg-group-bg p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center text-group-text">Inloggen</h2>
        <form onSubmit={handleLogin} className="space-y-4 mb-10">
          <input
            type="email"
            className="w-full p-2 border rounded bg-group-bg text-group-text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
            required
          />
          <input
            type="password"
            className="w-full p-2 border rounded bg-group-bg text-group-text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Wachtwoord"
            required
          />
          <button type="submit" className="w-full bg-accent text-header-text p-2 rounded hover:bg-opacity-80" disabled={false}>
            {false ? 'Aan het inloggen...' : 'Inloggen'}
          </button>
        </form>
        {/* <Link to="/register" className="w-full bg-add-group-btn text-add-group-btn-text p-2 rounded mt-10 hover:text-accent">
            Nog geen account? Registreer
          </Link> */}
        <button
          onClick={handleForgotPassword}
          className="w-full bg-add-group-btn text-add-group-btn-text p-2 rounded mt-0 hover:bg-opacity-80 hover:text-accent"
        >
          Wachtwoord vergeten?
        </button>
      </div>
    </div>
  );
};

export default Login;