import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCheck, faBuilding, faClock, faFaceSmileWink, faClipboardCheck, faLock, faDatabase, faTrash } from '@fortawesome/free-solid-svg-icons';
import ExplainedGroupView from './ExplainedGroupView';
import { AgeRange, Caretaker, Group } from '../Types';
import { determineRequiredCaretakers } from '../utils/DaycarePlanner';

interface LandingPageProps {
  currentUser: any;
  handleLogout: () => void;
}

const LandingPage: React.FC<LandingPageProps> = ({ currentUser, handleLogout }) => {
  const navigate = useNavigate();

  const handleContactRedirect = (reason: string) => {
    navigate(`/contact?reason=${reason}`);
  };

  // Updated sample data for the readonly group
  const sampleGroup: Group = {
    id: 'sample',
    title: 'Peutergroep', // Added group title
    ageRanges: ['0-1' as AgeRange, '1-2' as AgeRange, '2-3' as AgeRange, '3-4' as AgeRange],
    children: {
      '0-1': 0,
      '1-2': 0,
      '2-3': 6,
      '3-4': 9
    },
    temporaryCaretakers: [],
    caretakerIds: ['1', '2'], // Added caretaker IDs
    caretakerNotes: {}
  };

  const sampleGroups = [sampleGroup];
  const sampleCaretakers: Caretaker[] = [
    { id: '1', name: 'Marie', availability: 100, isSick: false, isTemporary: false },
    { id: '2', name: 'Sophie', availability: 100, isSick: false, isTemporary: false },
  ];

  // Sample functions (these won't actually do anything in readonly mode)
  const dummyFunction = () => {};

  // Updated getGroupStatus function
  const getGroupStatus = (group: Group, caretakersToUse: Caretaker[]) => {
    if(caretakersToUse.length === 1) {
      return {
        status: 'understaffed'
      }
    }
    return {
      status: 'overstaffed',
      adjustments: {
        '0-1': { count: 0, solvesProblem: true },
        '1-2': { count: 0, solvesProblem: true },
        '2-3': { count: 1, solvesProblem: true },
        '3-4': { count: 1, solvesProblem: true }
      }
    };
  }

  return (
    <div className="flex flex-col min-h-screen bg-main-bg text-group-text">
      {/* Hero Section */}
      <section className="w-full py-20 bg-header-bg text-header-text text-center relative">
        <h1 className="text-5xl font-bold mb-8">Welkom bij GroepsPlanner</h1>
        <p className="text-2xl mb-8">Beheer uw kinderdagverblijfgroepen efficiënt en eenvoudig</p>
        <Link to="/contact?reason=other" className="bg-accent text-header-text px-6 py-3 rounded-lg text-lg hover:bg-opacity-80 inline-flex items-center">
          <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
          Neem contact op
        </Link>
        {/* Curved separator */}
        <div className="absolute bottom-0 left-0 w-full overflow-hidden" style={{ height: '50px' }}>
          <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
            <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: 'var(--color-groupBackground)' }}></path>
          </svg>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="w-full py-16 bg-group-bg">
        <div className="container mx-auto px-4">
          <ul className="text-lg -mt-8 mb-10 list-none max-w-md mx-auto text-left">
            <li className="flex items-start mb-6">
              <FontAwesomeIcon icon={faClock} className="text-accent mr-6 mt-1 flex-shrink-0" />
              <span><b>Minder tijd</b> kwijt aan het plannen van uw groepen</span>
            </li>
            <li className="flex items-start mb-6">
              <FontAwesomeIcon icon={faCheck} className="text-accent mr-6 mt-1 flex-shrink-0" />
              <span><b>Minder fouten</b> dankzij automatische controles BKR</span>
            </li>
            <li className="flex items-start mb-6">
              <FontAwesomeIcon icon={faFaceSmileWink} className="text-accent mr-6 mt-1 flex-shrink-0" />
              <span><b>Minder stress</b> door inzicht in schuifmogelijkheden</span>
            </li>
          </ul>
          <div className="w-full max-w-[100px] mx-auto border-t border-gray-300 my-8"></div>
          <h2 className="text-3xl font-bold mb-6 text-center">Vereenvoudig uw kinderdagverblijfbeheer</h2>
          <div className="w-full max-w-[100px] mx-auto border-t border-gray-300 my-8"></div>
          <p className="text-lg mb-8 text-center">
            GroepsPlanner is ontworpen om het proces van organiseren en beheren van kinderdagverblijfgroepen te stroomlijnen. 
            Onze intuïtieve interface stelt u in staat om eenvoudig groepen te maken, pedagogisch medewerkers toe te wijzen en ervoor te 
            zorgen dat de juiste personeelsbezetting wordt gehandhaafd.
          </p>
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <div className="text-center bg-white rounded-lg shadow-md p-6 transition-transform hover:scale-105">
              <h3 className="text-2xl font-semibold italic mb-4">Inzicht in BKR</h3>
              <p className="text-lg">Realtime inzicht in schuifmogelijkheden binnen de richtlijnen van de BKR</p>
            </div>
            <div className="text-center bg-white rounded-lg shadow-md p-6 transition-transform hover:scale-105">
              <h3 className="text-2xl font-semibold italic mb-4">Personeelsbeheer</h3>
              <p className="text-lg">Wijs eenvoudig pedagogisch medewerkers toe aan groepen en beheer hun inzetbaarheid</p>
            </div>
            <div className="text-center bg-white rounded-lg shadow-md p-6 transition-transform hover:scale-105">
              <h3 className="text-2xl font-semibold italic mb-4">Eenvoudig delen</h3>
              <p className="text-lg">Deel uw planning snel en veilig met collega's via een unieke link</p>
            </div>
          </div>
          
          {/* Example Group Display with Explanations */}
          <p className="text-lg italic text-center mb-8">
            Klik op een onderdeel om meer te leren over de functionaliteit
          </p>
          <div className="max-w-md mx-auto">
            <ExplainedGroupView
              groups={sampleGroups}
              caretakers={sampleCaretakers}
              groupNotes={{ sample: '1 extra plek' }}
              getGroupStatus={getGroupStatus}
              getTotalChildCount={() => 15}
              determineRequiredCaretakers={determineRequiredCaretakers}
              updateGroupTitle={dummyFunction}
              updateGroupNotes={dummyFunction}
              updateChildCount={dummyFunction}
              deleteGroup={dummyFunction}
              assignCaretaker={dummyFunction}
              unassignCaretaker={dummyFunction}
              addGroup={dummyFunction}
              addTemporaryCaretaker={dummyFunction}
              readOnly={true}
              isLandingPage={true} // Add this prop
              updateCaretakerNote={dummyFunction}
              planningTitle=""
              updatePlanningTitle={dummyFunction}
              planningNotes=""
              updatePlanningNotes={dummyFunction}
              toggleCaretakerSickStatus={dummyFunction}
              onReorderCaretakers={dummyFunction}
              moveCaretaker={dummyFunction}
              showAlert={dummyFunction}
              deleteAllGroups={dummyFunction}
              resetAllGroups={dummyFunction}
            />
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="w-full py-16 bg-main-bg">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-6 text-center">Prijzen</h2>
          <div className="w-full max-w-[100px] mx-auto border-t border-gray-300 my-8"></div>
          <div className="flex flex-wrap justify-center gap-8">
            <div className="bg-group-bg rounded-lg shadow-lg p-8 w-full max-w-sm">
              <h3 className="text-2xl font-bold mb-4 text-center">Basis</h3>
              <span className="text-4xl font-bold text-center mb-6">€50<p className="text-sm font-normal italic ml-2 mb-6">per maand, per vestiging</p></span>
              <ul className="mb-8">
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Tot 4 groepen</li>
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Tot 8 medewerkers</li>
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Realtime updates</li>
              </ul>
              <button onClick={() => handleContactRedirect('basis')} className="w-full bg-footer-bg text-header-text py-2 rounded-lg hover:bg-opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105">Kies voor Basis</button>
            </div>
            <div className="bg-group-bg rounded-lg shadow-lg p-8 w-full max-w-sm">
              <h3 className="text-2xl font-bold mb-4 text-center">Pro</h3>
              <span className="text-4xl font-bold text-center mb-6">€100<p className="text-sm font-normal italic ml-2 mb-6">per maand, per vestiging</p></span>              
              <ul className="mb-8">
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Onbeperkt aantal groepen</li>
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Onbeperkt aantal medewerkers</li>
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> AI-gestuurde planningsoplossingen *</li>
              </ul>
              <button onClick={() => handleContactRedirect('pro')} className="w-full bg-footer-bg text-header-text py-2 rounded-lg hover:bg-opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105">Kies voor Pro</button>
              <p className="text-xs italic mt-2 -mb-3">* Functionaliteit in ontwikkeling</p>
            </div>
            <div className="bg-group-bg rounded-lg shadow-lg p-8 w-full max-w-sm">
              <h3 className="text-2xl font-bold mb-4 text-center">Pro met ondersteuning</h3>
              <span className="text-4xl font-bold text-center mb-6"><span className="line-through">€125</span> €100<p className="text-sm font-normal italic ml-2 mb-6">per maand, per vestiging</p></span>
              <ul className="mb-8">
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Alle Pro-functies</li>
                <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Persoonlijke ondersteuning</li>
              </ul>
              <button onClick={() => handleContactRedirect('pro-support')} className="w-full bg-footer-bg text-header-text py-6 rounded-lg hover:bg-opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105">Kies voor Pro met ondersteuning</button>
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <div className="bg-group-bg rounded-lg shadow-lg p-6 max-w-md">
              <div className="flex justify-center mb-4">
                <FontAwesomeIcon icon={faBuilding} className="text-4xl text-accent" />
              </div>
              <p className="mb-4 text-lg text-center">Heeft u meer dan 5 vestigingen? Wij bieden speciale Enterprise oplossingen.</p>
              <div className="text-center">
                <button onClick={() => handleContactRedirect('enterprise')} className="bg-accent text-header-text py-2 px-4 rounded-lg hover:bg-opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105">
                  Neem contact op voor Enterprise licenties
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="w-full py-16 bg-group-bg">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-6 text-center">Over GroepsPlanner</h2>
          <div className="w-full max-w-[100px] mx-auto border-t border-gray-300 my-8"></div>
          <p className="text-lg mb-8 text-center">
            Wij begrijpen de dagelijkse uitdagingen in de kinderopvang. 
            GroepsPlanner is ontworpen om de complexe taak van groepsplanning te vereenvoudigen en uw werkdag te optimaliseren. 
            Ons doel is om pedagogisch medewerkers te ondersteunen met een <b>intuïtief</b> en<b> efficiënt hulpmiddel</b> dat helpt met het oplossen van de dagelijkse puzzel.
          </p>
          
          <p className="text-lg mb-8 text-center">
           Door groepsplanning <b>eenvoudiger</b> en <b>efficiënter</b> te maken, kunt u zich concentreren op wat echt belangrijk is - 
            het bieden van uitstekende zorg voor de kinderen.
          </p>
        </div>
      </section>
      <section className="w-full py-16 bg-main-bg">
        <div className="container mx-auto px-4">
          <h3 className="text-3xl font-bold mb-6 text-center">Privacy en Gegevensbescherming</h3>
          <div className="w-full max-w-[100px] mx-auto border-t border-gray-300 my-8"></div>
          <p className="text-lg mb-8 text-center">
            Bij GroepsPlanner hechten we grote waarde aan uw privacy en de bescherming van uw gegevens.
          </p>
          <ul className="text-lg my-10 list-none max-w-md mx-auto text-left">
            <li className="flex items-start mb-6">
              <FontAwesomeIcon icon={faDatabase} className="text-accent mr-6 mt-1 flex-shrink-0" />
              <span>We slaan geen andere gegevens op dan uw accountgegevens (e-mailadres)</span>
            </li>
            <li className="flex items-start mb-6">
              <FontAwesomeIcon icon={faTrash} className="text-accent mr-6 mt-1 flex-shrink-0" />
              <span>Gedeelde planningen worden na 30 dagen automatisch verwijderd</span>
            </li>
            <li className="flex items-start mb-6">
              <FontAwesomeIcon icon={faClipboardCheck} className="text-accent mr-6 mt-1 flex-shrink-0" />
              <span>We zijn volledig GDPR-compliant</span>
            </li>
          </ul>
          <div className="text-center mt-10 bg-group-bg w-1/6 min-w-[300px] mx-auto rounded-lg shadow-lg p-6">
            <FontAwesomeIcon icon={faLock} className="text-accent text-4xl mb-4" />
            <p className="text-lg">Uw privacy en gegevensbescherming staan bij ons voorop</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;