import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Make sure this path is correct

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialReason = searchParams.get('reason');
    if (initialReason) {
      setReason(initialReason);
    }
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitStatus('submitting');

    try {
      await addDoc(collection(db, 'contactSubmissions'), {
        name,
        email,
        reason,
        message: reason === 'other' ? message : '',
        timestamp: new Date()
      });

      setSubmitStatus('success');
      // Reset form fields
      setName('');
      setEmail('');
      setReason('');
      setMessage('');
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
  };

  return (
    <div className="flex flex-col bg-main-bg text-group-text">
      {/* Hero Section */}
      <section className="w-full py-20 bg-header-bg text-header-text text-center relative">
        <h1 className="text-5xl font-bold mb-8">Neem Contact Op</h1>
        <p className="text-2xl mb-8">We horen graag van u</p>
        {/* Curved separator */}
        <div className="absolute bottom-0 left-0 w-full overflow-hidden" style={{ height: '50px' }}>
          <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
            <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: 'var(--color-mainBackground)' }}></path>
          </svg>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="w-full py-20 bg-main-bg">
        <div className="container mx-auto px-4">
        {submitStatus !== 'success' && <h2 className="text-3xl font-bold mb-8 text-center">Contact</h2> }
          {submitStatus === 'success' ? (
            <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg text-center">
              <p className="text-xl text-accent mb-4">Bedankt voor uw bericht!</p>
              <p>We nemen zo snel mogelijk contact met u op.</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg">
              <div className="mb-4">
                <label htmlFor="reason" className="block text-sm font-medium text-gray-700 mb-1">Reden voor contact</label>
                <select
                  id="reason"
                  className="w-full p-2 border rounded bg-group-bg text-group-text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                >
                  <option value="">Selecteer een reden</option>
                  <option value="basis">Basis plan</option>
                  <option value="pro">Pro plan</option>
                  <option value="pro-support">Pro plan met ondersteuning</option>
                  <option value="enterprise">Enterprise licenties</option>
                  <option value="other">Anders</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Naam</label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-2 border rounded bg-group-bg text-group-text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">E-mail</label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border rounded bg-group-bg text-group-text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              {reason === 'other' && (
                <div className="mb-4">
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Bericht</label>
                  <textarea
                    id="message"
                    className="w-full p-2 border rounded bg-group-bg text-group-text"
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
              )}
              <button 
                type="submit" 
                className="w-full bg-accent text-header-text p-2 rounded hover:bg-opacity-80"
                disabled={submitStatus === 'submitting'}
              >
                {submitStatus === 'submitting' ? 'Verzenden...' : 'Verstuur'}
              </button>
              {submitStatus === 'error' && (
                <p className="mt-4 text-red-500 text-center">Er is een fout opgetreden. Probeer het later opnieuw.</p>
              )}
            </form>
          )}
        </div>
      </section>
    </div>
  );
};

export default Contact;