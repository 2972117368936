import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCompressedData } from '../utils/urlShortener';

const ShareRedirect: React.FC = () => {
  const { shortcode } = useParams<{ shortcode: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (shortcode) {
        try {
          const compressedData = await getCompressedData(shortcode);
          if (compressedData) {
            navigate(`/view/${compressedData}`);
          } else {
            console.error('Geen gecomprimeerde data gevonden voor shortcode:', shortcode);
            setError('Er is iets misgegaan. dit lijkt een ongeldige of verlopen link.');
          }
        } catch (error) {
          console.error('Fout bij het ophalen van gecomprimeerde data:', error);
          setError('Er is iets misgegaan. Er is een fout opgetreden bij het laden van de gegevens.');
        }
      } else {
        console.error('Geen shortcode opgegeven');
        navigate('/');
      }
    };

    fetchData();
  }, [shortcode, navigate]);

  if (error) {
    return (
      <div className="flex items-center justify-center h-full mt-20">
        <div className="bg-group-bg p-8 rounded-lg shadow-lg max-w-md">
          <h2 className="text-2xl font-bold mb-4 text-group-text">Oeps!</h2>
          <p className="text-group-text">{error}</p>
          <button
            onClick={() => navigate('/')}
            className="mt-4 bg-accent text-white py-2 px-4 rounded hover:bg-accent-dark transition duration-300"
          >
            Ga naar de startpagina
          </button>
        </div>
      </div>
    );
  }

  return <div className="text-center mt-8">Aan het laden...</div>;
};

export default ShareRedirect;