import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEnvelope, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

const AccountPage: React.FC = () => {
  const { user } = useAuth();

  const getAccountTypeInfo = () => {
    switch (user?.permissionLevel) {
      case 0:
        return {
          name: 'Gratis',
          color: 'text-gray-500',
          features: ['Geen functies']
        };
      case 1:
        return {
          name: 'Basis',
          color: 'text-blue-500',
          features: ['Maak tot 4 groepen', 'Voeg tot 8 medewerkers toe', 'Basis planningsfuncties']
        };
      case 2:
        return {
          name: 'Pro',
          color: 'text-green-500',
          features: ['Onbeperkt aantal groepen', 'Onbeperkt aantal medewerkers', 'Geavanceerde planningsfuncties']
        };
      case 3:
        return {
          name: 'Pro Plus',
          color: 'text-purple-500',
          features: ['Alle Pro-functies', 'Prioritaire ondersteuning']
        };
      case 4:
        return {
          name: 'Admin',
          color: 'text-red-500',
          features: ['Volledige toegang', 'Beheerdersrechten']
        };
      default:
        return {
          name: 'Onbekend',
          color: 'text-gray-500',
          features: []
        };
    }
  };

  const accountInfo = getAccountTypeInfo();

  return (
    <div className="container mx-auto mt-10 p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center">Account</h1>
        <hr className="mb-4 w-full max-w-[100px] mx-auto border-group-text opacity-20" />
        <div className="flex justify-center items-center mb-4">
          <div>
            <h2 className="text-xl font-semibold">Accountgegevens</h2>
            <p className="text-gray-600">{user?.email}</p>
          </div>
        </div>
        <div className="flex justify-center items-center mb-6">
          <div>
            <h2 className="text-xl font-semibold">Accounttype</h2>
            <p className={`font-medium ${accountInfo.color}`}>{accountInfo.name}</p>
          </div>
        </div>
        
        <div className="flex flex-col lg:flex-row justify-center gap-4 lg:gap-8">
          <div className="bg-group-bg rounded-lg shadow-lg p-4 lg:p-8 w-full lg:w-1/3">
            <h3 className="text-2xl font-bold mb-4 text-center">Basis</h3>
            <span className="text-4xl font-bold text-center mb-6">€50<p className="text-sm font-normal italic ml-2 mb-6">per maand, per vestiging</p></span>
            <ul className="mb-8">
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Tot 4 groepen</li>
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Tot 8 medewerkers</li>
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Realtime updates</li>
            </ul>
            <button 
              onClick={() => {}} 
              className={`w-full bg-footer-bg text-header-text py-2 rounded-lg ${user?.permissionLevel === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105'}`}
              disabled={user?.permissionLevel === 1}
            >
              {user?.permissionLevel === 1 ? 'Huidige plan' : 'Kies voor Basis'}
            </button>
          </div>
          <div className="bg-group-bg rounded-lg shadow-lg p-4 lg:p-8 w-full lg:w-1/3">
            <h3 className="text-2xl font-bold mb-4 text-center">Pro</h3>
            <span className="text-4xl font-bold text-center mb-6">€100<p className="text-sm font-normal italic ml-2 mb-6">per maand, per vestiging</p></span>              
            <ul className="mb-8">
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Onbeperkt aantal groepen</li>
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Onbeperkt aantal medewerkers</li>
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> AI-gestuurde planningsoplossingen *</li>
            </ul>
            <button 
              onClick={() => {}} 
              className={`w-full bg-footer-bg text-header-text py-2 rounded-lg ${user?.permissionLevel === 2 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105'}`}
              disabled={user?.permissionLevel === 2}
            >
              {user?.permissionLevel === 2 ? 'Huidige plan' : 'Kies voor Pro'}
            </button>
            <p className="text-xs italic mt-2 -mb-3">* Functionaliteit in ontwikkeling</p>
          </div>
          <div className="bg-group-bg rounded-lg shadow-lg p-4 lg:p-8 w-full lg:w-1/3">
            <h3 className="text-2xl font-bold mb-4 text-center">Pro met ondersteuning</h3>
            <span className="text-4xl font-bold text-center mb-6"><span className="line-through">€125</span> €100<p className="text-sm font-normal italic ml-2 mb-6">per maand, per vestiging</p></span>
            <ul className="mb-8">
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Alle Pro-functies</li>
              <li className="flex items-center mb-2"><FontAwesomeIcon icon={faCheck} className="text-accent mr-2" /> Persoonlijke ondersteuning</li>
            </ul>
            <button 
              onClick={() => {}} 
              className={`w-full bg-footer-bg text-header-text py-6 rounded-lg ${user?.permissionLevel === 3 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105'}`}
              disabled={user?.permissionLevel === 3}
            >
              {user?.permissionLevel === 3 ? 'Huidige plan' : 'Kies voor Pro met ondersteuning'}
            </button>
          </div>
        </div>

        <div className="mt-8 pt-6 border-t border-gray-200">
          <h3 className="text-lg font-semibold mb-4">Accountbeheer</h3>
          <div className="space-y-4">
            <button className="flex items-center text-gray-700 hover:text-blue-500 transition duration-300 mx-auto">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              E-mailadres wijzigen
            </button>
            <button className="flex items-center text-gray-700 hover:text-blue-500 transition duration-300 mx-auto">
              <FontAwesomeIcon icon={faLock} className="mr-2" />
              Wachtwoord wijzigen
            </button>
            {user && user.permissionLevel > 0 && (
              <button className="flex items-center text-gray-700 hover:text-red-500 transition duration-300 mx-auto">
                <FontAwesomeIcon icon={faUnlock} className="mr-2" />
                Account downgraden
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;