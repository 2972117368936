import { deflate, inflate } from 'pako';

export function compressData(data: any): string {
  const jsonString = JSON.stringify(data);
  const compressed = deflate(jsonString);
  return btoa(String.fromCharCode.apply(null, compressed as unknown as number[]))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

export function decompressData(compressedData: string): any {
  const base64 = compressedData.replace(/-/g, '+').replace(/_/g, '/');
  const charData = atob(base64).split('').map(x => x.charCodeAt(0));
  const inflated = inflate(new Uint8Array(charData));
  const decompressed = new TextDecoder().decode(inflated);
  return JSON.parse(decompressed);
}