import { Footnote, GroupDefinition } from "../Types";

// Footnotes definitions
const footnotes: Footnote[] = [
    {
      id: 1,
      description: 'Waarvan maximaal acht kinderen van 0 tot 1 jaar.',
      maxChildrenOfAge0to1: 8,
    },
    {
      id: 2,
      description: 'Waarvan maximaal drie kinderen van 0 tot 1 jaar.',
      maxChildrenOfAge0to1: 3,
    },
    {
      id: 3,
      description: 'Waarvan maximaal vijf kinderen van 0 tot 1 jaar.',
      maxChildrenOfAge0to1: 5,
    },
    {
      id: 4,
      description: 'Waarvan maximaal vier kinderen van 0 tot 1 jaar.',
      maxChildrenOfAge0to1: 4,
    },
  ];
  
  // Define the table data based on your provided table
  const groupDefinitions: GroupDefinition[] = [
    // **Groepen alle kinderen één leeftijd**
    {
      ageRanges: ['0-1'],
      columns: [
        { minCaretakers: 1, maxChildren: 3 },
        { minCaretakers: 2, maxChildren: 6 },
        { minCaretakers: 3, maxChildren: 9 },
        { minCaretakers: 4, maxChildren: 12 },
      ],
    },
    {
      ageRanges: ['1-2'],
      columns: [
        { minCaretakers: 1, maxChildren: 5 },
        { minCaretakers: 2, maxChildren: 10 },
        { minCaretakers: 3, maxChildren: 15 },
        { minCaretakers: 4, maxChildren: 16 },
      ],
    },
    {
      ageRanges: ['2-3'],
      columns: [
        { minCaretakers: 1, maxChildren: 8 },
        { minCaretakers: 2, maxChildren: 16 },
      ],
    },
    {
      ageRanges: ['3-4'],
      columns: [
        { minCaretakers: 1, maxChildren: 8 },
        { minCaretakers: 2, maxChildren: 16 },
      ],
    },
    // **Gemengde leeftijdsgroepen**
    {
      ageRanges: ['0-1', '1-2'],
      columns: [
        { minCaretakers: 1, maxChildren: 4 },
        { minCaretakers: 2, maxChildren: 8 },
        { minCaretakers: 3, maxChildren: 14, footnotes: [1] },
        { minCaretakers: 4, maxChildren: 16, footnotes: [1] },
      ],
    },
    {
      ageRanges: ['0-1', '1-2', '2-3'],
      columns: [
        { minCaretakers: 1, maxChildren: 5 },
        { minCaretakers: 2, maxChildren: 10 },
        { minCaretakers: 3, maxChildren: 15, footnotes: [2] },
        { minCaretakers: 3, maxChildren: 14, footnotes: [4] },
        { minCaretakers: 3, maxChildren: 13, footnotes: [1] },
        { minCaretakers: 4, maxChildren: 16, footnotes: [1] },
      ],
    },
    {
      ageRanges: ['0-1', '1-2', '2-3', '3-4'],
      columns: [
        { minCaretakers: 1, maxChildren: 5 },
        { minCaretakers: 2, maxChildren: 12 },
        { minCaretakers: 3, maxChildren: 16, footnotes: [2] },
        { minCaretakers: 3, maxChildren: 15, footnotes: [3] },
        { minCaretakers: 3, maxChildren: 14, footnotes: [1] },
        { minCaretakers: 4, maxChildren: 16, footnotes: [1] },
      ],
    },
    {
      ageRanges: ['1-2', '2-3'],
      columns: [
        { minCaretakers: 1, maxChildren: 6 },
        { minCaretakers: 2, maxChildren: 11 },
        { minCaretakers: 3, maxChildren: 16 },
      ],
    },
    {
      ageRanges: ['1-2', '2-3', '3-4'],
      columns: [
        { minCaretakers: 1, maxChildren: 7 },
        { minCaretakers: 2, maxChildren: 13 },
        { minCaretakers: 3, maxChildren: 16 },
      ],
    },
    {
      ageRanges: ['2-3', '3-4'],
      columns: [
        { minCaretakers: 1, maxChildren: 8 },
        { minCaretakers: 2, maxChildren: 16 },
      ],
    },
  ];

  export { groupDefinitions, footnotes };