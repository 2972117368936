import { AgeRange, GroupDefinition, Group, Caretaker } from '../Types';
import { groupDefinitions, footnotes } from './DaycareTable';

// Helper function to find the group definition
function findGroupDefinition(group: Group): GroupDefinition | undefined {
  const ageRanges = group.ageRanges

  // Remove empty age ranges from the edges
  let start = 0;
  let end = ageRanges.length - 1;

  while (start <= end && (group.children[ageRanges[start]] || 0) === 0) {
    start++;
  }

  while (end >= start && (group.children[ageRanges[end]] || 0) === 0) {
    end--;
  }

  const trimmedAgeRanges = ageRanges.slice(start, end + 1);

  return groupDefinitions.find((def) => {
    return (
      def.ageRanges.length === trimmedAgeRanges.length &&
      def.ageRanges.every((age, index) => age === trimmedAgeRanges[index])
    );
  });
}

// Helper function to check if footnotes are satisfied
function checkFootnotes(
  footnoteIds: number[],
  children: { [key in AgeRange]?: number }
): boolean {
  for (const id of footnoteIds) {
    const footnote = footnotes.find((fn) => fn.id === id);
    if (footnote) {
      // Check the conditions specified in the footnote
      if (footnote.maxChildrenOfAge0to1 !== undefined) {
        if ((children['0-1'] || 0) <= footnote.maxChildrenOfAge0to1) {
          return true;
        }
      }
      // Add additional footnote conditions here if necessary
    } else {
      // If footnote is not found, assume it fails
      return true;
    }
  }
  return false;
}

// Function to determine the required number of caretakers
function determineRequiredCaretakers(group: Group): number {
  const totalChildren = Object.values(group.children).reduce((a, b) => a + b, 0);
  const groupDef = findGroupDefinition(group);

  if (!groupDef) {
    return 0;
  }

  // Remove empty age ranges
  const nonEmptyAgeRanges = group.ageRanges.filter(ageRange => (group.children[ageRange] || 0) > 0);
  
  // If all age ranges are empty, return 0 caretakers required
  if (nonEmptyAgeRanges.length === 0) {
    return 0;
  }
  
  // Update the group with non-empty age ranges
  const updatedGroup = {
    ...group,
    ageRanges: nonEmptyAgeRanges,
    children: Object.fromEntries(
      Object.entries(group.children).filter(([ageRange, count]) => count > 0)
    ) as { [key in AgeRange]?: number }
  };

  // Use the updated group for further calculations
  group = updatedGroup;

  let requiredCaretakers = 0;

  // Look up the required number of caretakers in the table
  let prevColumn = groupDef.columns[0];
  for (const column of groupDef.columns) {
    if (totalChildren <= column.maxChildren) {
      if (column.footnotes && !checkFootnotes(column.footnotes, group.children)) {
        continue;
      }
      const remainingChildren = totalChildren - prevColumn.maxChildren;
      const div = column.maxChildren - prevColumn.maxChildren;
      const fraction = div === 0 ? 0 : remainingChildren / div;
      requiredCaretakers = prevColumn.minCaretakers + fraction * (column.minCaretakers - prevColumn.minCaretakers);
      break;
    }
    prevColumn = column;
  }

  if (requiredCaretakers === 0) {
    return 0;
  }

  // in a combined group, its never allowed to have > 8 children aged 0-1
  if(group.ageRanges.length > 1 && (group.children['0-1'] || 0) > 8) {
    return 0;
  }

  // Apply Rekenregel 1 if there is at least one child aged 0-1
  if ((group.children['0-1'] || 0) >= 1) {
    const A = (group.children['0-1'] || 0) / 3;
    const B = (group.children['1-2'] || 0) / 5;
    const C = (group.children['2-3'] || 0) / 6;
    const D = (group.children['3-4'] || 0) / 8;
    const Z = A + ((B + C + D) / 1.2);

    if (Z > requiredCaretakers) {
      requiredCaretakers = Z;
    }
  }

  // Apply Rekenregel 2
  const caretakersNow = requiredCaretakers;

  // Function to calculate caretakers without a specific age group
  const calculateCaretakersWithoutAgeGroup = (ageToRemove: AgeRange): number => {
    const newGroup: Group = {
      title: group.title,
      id: group.id,
      ageRanges: group.ageRanges.filter((age) => age !== ageToRemove),
      children: { ...group.children },
      caretakerNotes: { ...group.caretakerNotes },
      temporaryCaretakers: Array.isArray(group.temporaryCaretakers) ? [...group.temporaryCaretakers] : [],
      caretakerIds: [...group.caretakerIds || []], // Add this line
    };
    delete newGroup.children[ageToRemove];
    if(newGroup.ageRanges.length === 0) {
      return 0;
    }
    return determineRequiredCaretakers(newGroup);
  };

  // Remove lowest age group
  const ageGroupsSorted = Object.keys(group.children).sort();
  const lowestAgeGroup = ageGroupsSorted[0] as AgeRange;
  const caretakersWithoutLowest = calculateCaretakersWithoutAgeGroup(lowestAgeGroup);

  if (caretakersWithoutLowest > caretakersNow) {
    requiredCaretakers = Math.max(caretakersWithoutLowest, caretakersNow);
  }

  // Remove highest age group
  const highestAgeGroup = ageGroupsSorted[ageGroupsSorted.length - 1] as AgeRange;
  const caretakersWithoutHighest = calculateCaretakersWithoutAgeGroup(highestAgeGroup);

  if (caretakersWithoutHighest > caretakersNow) {
    requiredCaretakers = Math.max(caretakersWithoutHighest, caretakersNow);
  }

  return requiredCaretakers;
}

// Function to determine staffing adjustments
function staffingAdjustment(
  group: Group,
  caretakers: Caretaker[]
): { 
  status: 'overstaffed' | 'understaffed' | 'properly-staffed' | 'invalid'; 
  adjustments: { [key in AgeRange]: { count: number; solvesProblem: boolean } };
} {
  const requiredCaretakers = determineRequiredCaretakers(group);

  if (requiredCaretakers === 0 && Object.values(group.children).some(count => count > 0)) {
    return { 
      status: 'invalid', 
      adjustments: { 
        '0-1': { count: 0, solvesProblem: false }, 
        '1-2': { count: 0, solvesProblem: false }, 
        '2-3': { count: 0, solvesProblem: false }, 
        '3-4': { count: 0, solvesProblem: false } 
      } 
    };
  }

  const availableCaretakers = caretakers
    .filter(c => group.caretakerIds ? group.caretakerIds.includes(c.id) || c.isTemporary : false)
    .reduce((sum, caretaker) => sum + (caretaker.availability > 0 ? Math.max(caretaker.availability, 34) / 100 : 0), 0);

  let adjustments: { [key in AgeRange]: { count: number; solvesProblem: boolean } } = {
    '0-1': { count: 0, solvesProblem: false },
    '1-2': { count: 0, solvesProblem: false },
    '2-3': { count: 0, solvesProblem: false },
    '3-4': { count: 0, solvesProblem: false }
  };

  if (availableCaretakers < requiredCaretakers) {
    // Understaffed
    for (const ageRange of group.ageRanges) {
      let tempGroup = { ...group, children: { ...group.children } };
      let childrenToRemove = 0;

      while (tempGroup.children[ageRange]! > 0) {
        tempGroup.children[ageRange] = (tempGroup.children[ageRange] || 0) - 1;
        childrenToRemove += 1;

        const newRequiredCaretakers = determineRequiredCaretakers(tempGroup);
        if (newRequiredCaretakers <= availableCaretakers) {
          adjustments[ageRange] = { count: -childrenToRemove, solvesProblem: true };
          break;
        }
      }

      if (adjustments[ageRange].count === 0) {
        // If we've removed all children in this age range and it's still not enough
        adjustments[ageRange] = { count: -childrenToRemove, solvesProblem: false };
      }
    }

    return { status: 'understaffed', adjustments };
  } else {
    // Properly staffed or overstaffed
    for (const ageRange of group.ageRanges) {
      let tempGroup = { ...group, children: { ...group.children } };
      let additionalChildren = 0;

      while (true) {
        tempGroup.children[ageRange] = (tempGroup.children[ageRange] || 0) + 1;
        
        const newRequiredCaretakers = determineRequiredCaretakers(tempGroup);
        if (newRequiredCaretakers > availableCaretakers || newRequiredCaretakers === 0) {
          break;
        }
        additionalChildren += 1;
      }

      adjustments[ageRange] = { count: additionalChildren, solvesProblem: true };
    }

    const isProperlyStaffed = Object.values(adjustments).every(adj => adj.count === 0);

    return { 
      status: isProperlyStaffed ? 'properly-staffed' : 'overstaffed', 
      adjustments 
    };
  }
}

export {
  determineRequiredCaretakers,
  staffingAdjustment,
};