import React, { useState, useRef, useEffect } from 'react';
import { Caretaker } from '../Types';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faSortAlphaDown, faSortAlphaUp, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../contexts/AuthContext';

interface CaretakerModalProps {
  caretakers: Caretaker[];
  onClose: () => void;
  onAddCaretaker: (name: string, availability: number) => void;
  onRemoveCaretaker: (id: string) => void;
  onUpdateAvailability: (id: string, availability: number) => void;
  onReorderCaretakers: (reorderedCaretakers: Caretaker[]) => void;
  onRemoveAllCaretakers: () => void;
  showConfirmDialog: (message: string, onConfirm: () => void) => void;
  isAnotherModalOpen: boolean; // New prop
  showAlert: (message: string, onConfirm: () => void) => void;
}

const CaretakerModal: React.FC<CaretakerModalProps> = ({
  caretakers,
  onClose,
  onAddCaretaker,
  onRemoveCaretaker,
  onUpdateAvailability,
  onReorderCaretakers,
  onRemoveAllCaretakers,
  showConfirmDialog,
  isAnotherModalOpen, // New prop
  showAlert
}) => {
  const { user } = useAuth();

  const [newCaretakerName, setNewCaretakerName] = useState('');
  const [newCaretakerAvailability, setNewCaretakerAvailability] = useState(100);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const modalRef = useRef<HTMLDivElement>(null);
  const newCaretakerNameRef = useRef<HTMLInputElement>(null);

  const canAddCaretaker = () => {
    if (!user) return false;
    if (user.permissionLevel === 0) return false;
    if (user.permissionLevel === 1) return caretakers.length < 8;
    return true;
  };

  const handleAddCaretaker = (name: string, availability: number) => {
    const trimmedName = name.trim();
    if (trimmedName === '') {
      showAlert('Voer een geldige naam in voor de medewerker.', () => {});
      return;
    }

    // Check if the caretaker name already exists
    const caretakerExists = caretakers.some(
      (caretaker) => caretaker.name.toLowerCase() === trimmedName.toLowerCase()
    );
    if (caretakerExists) {
      showAlert('Er bestaat al een medewerker met deze naam. Kies een andere naam.', () => {});
      return;
    }

    if (!canAddCaretaker()) {
      showAlert('Je hebt de maximale limiet voor medewerkers bereikt. Upgrade je account om meer medewerkers toe te voegen.', () => {});
      return;
    }

    onAddCaretaker(trimmedName, availability);
    setNewCaretakerName('');
    setNewCaretakerAvailability(100);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddCaretaker(newCaretakerName, newCaretakerAvailability);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(caretakers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onReorderCaretakers(items);
  };

  const handleSort = () => {
    const sorted = [...caretakers].sort((a, b) => {
      const nameComparison = a.name.localeCompare(b.name);
      if (nameComparison !== 0) {
        return sortOrder === 'asc' ? nameComparison : -nameComparison;
      }
      return sortOrder === 'asc' 
        ? b.availability - a.availability 
        : a.availability - b.availability;
    });
    onReorderCaretakers(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleRemoveAllCaretakers = () => {
    showConfirmDialog(
      'Weet je zeker dat je alle medewerkers wilt verwijderen?',
      () => {
        onRemoveAllCaretakers();
        // The modal will stay open after removing all caretakers
      }
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node) && !isAnotherModalOpen) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isAnotherModalOpen]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  useEffect(() => {
    // Focus the new caretaker name input when the modal opens
    if (newCaretakerNameRef.current) {
      newCaretakerNameRef.current.focus();
    }
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center text-center z-50 p-4">
      <div ref={modalRef} className="bg-group-bg p-4 rounded-lg w-[32rem] max-w-full relative max-h-[90vh] overflow-y-auto">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-bold mb-4 text-group-text">Medewerkers</h2>
        <hr className="mb-4 w-full max-w-[100px] mx-auto border-group-text opacity-20" />
        <div className="flex justify-between items-center mb-2 font-bold text-group-text">
          <span className="w-1/12"><button onClick={handleSort} className="text-grou-text hover:text-accent">
          <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortAlphaDown : faSortAlphaUp} />
        </button></span>
          <span className="w-1/3">Naam</span>
          <span className="w-1/3">Inzetbaarheid (%)</span>
          
            <button 
              onClick={handleRemoveAllCaretakers}
              className="w-1/12 text-left bg-transparent hover:text-accent focus:outline-none"
              title="Verwijder alle medewerkers"
            >
              <FontAwesomeIcon icon={faTrash} className="ml-6" />
            </button>
        </div>
        
        
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="caretakers">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`py-2 max-h-[50vh] min-h-[2vh] bg-main-bg overflow-y-auto mb-4 -mx-2 rounded-lg ${snapshot.isDraggingOver ? 'bg-main-bg' : ''}`}
              >
                {caretakers.length === 0 ? (
                  <div className="text-group-text p-4">Nog geen medewerkers</div>
                ) : (
                  caretakers.map((caretaker, index) => (
                    <Draggable key={caretaker.id} draggableId={caretaker.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`flex justify-between items-center m-2 my-0 p-1 ${snapshot.isDragging ? 'bg-accent bg-opacity-20' : 'bg-group-bg'}`}
                        >
                          <span className="w-1/12" {...provided.dragHandleProps}>
                            <FontAwesomeIcon icon={faGripVertical} className="text-group-text opacity-50" />
                          </span>
                          <span className="w-1/3 text-group-text">
                            {caretaker.name}
                            {caretaker.isSick ? ' (0%, ziek)' : ''}
                          </span>
                          <select
                            className="w-1/3 p-1 border rounded bg-group-bg text-group-text"
                            value={caretaker.availability}
                            onChange={(e) => onUpdateAvailability(caretaker.id, Number(e.target.value))}
                            disabled={caretaker.isSick}
                          >
                            <option value={0}>0%</option>
                            <option value={25}>25%</option>
                            <option value={50}>50%</option>
                            <option value={75}>75%</option>
                            <option value={100}>100%</option>
                          </select>
                          <button onClick={() => onRemoveCaretaker(caretaker.id)} className="w-1/12 text-accent text-center hover:text-border-understaffed" aria-label="Verwijder medewerker">
                            ×
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <h2 className="text-2xl font-bold mb-4 text-group-text">Medewerker toevoegen</h2>
        <hr className="mb-4 w-full max-w-[100px] mx-auto border-group-text opacity-20" />
        <div className="flex flex-col">
          <div className="flex mb-2">
            <div className="w-1/2 mr-2">
              <label className="block text-sm font-medium text-group-text mb-1">Naam</label>
              <input
                ref={newCaretakerNameRef}
                type="text"
                className="w-full p-2 border rounded bg-group-bg text-group-text"
                value={newCaretakerName}
                onChange={(e) => setNewCaretakerName(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Naam"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-group-text mb-1">Inzetbaarheid (%)</label>
              <select
                className="w-full p-2 border rounded bg-group-bg text-group-text"
                value={newCaretakerAvailability}
                onChange={(e) => setNewCaretakerAvailability(Number(e.target.value))}
              >
                <option value={0}>0%</option>
                <option value={25}>25%</option>
                <option value={50}>50%</option>
                <option value={75}>75%</option>
                <option value={100}>100%</option>
              </select>
            </div>
          </div>
          <button onClick={() => handleAddCaretaker(newCaretakerName, newCaretakerAvailability)} className="w-full bg-accent text-header-text px-4 py-2 rounded hover:bg-opacity-80">Voeg medewerker toe</button>
        </div>
      </div>
    </div>
  );
};

export default CaretakerModal;