import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyD8PlgNEzPeZ98-KeaaoSdhRQtGqN7CW-c",
    authDomain: "groepsplanner-c7275.firebaseapp.com",
    projectId: "groepsplanner-c7275",
    storageBucket: "groepsplanner-c7275.appspot.com",
    messagingSenderId: "7509165398",
    appId: "1:7509165398:web:a55ef816bb1ad1597742f7"
  };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);