import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faShare, faBars, faSignOutAlt, faCalendarAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as BabyIcon } from '../baby.svg';
import AccountMenu from './AccountMenu';
import { useAuth } from '../contexts/AuthContext';

interface HeaderProps {
  isLandingPage: boolean;
  isPlanningPage: boolean;
  isViewMode: boolean;
  isContactPage: boolean;
  isAccountPage: boolean;
  handleEdit: () => void;
  encodeStateToURL: () => void;
  setIsCaretakerModalOpen: (isOpen: boolean) => void;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
  handleLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isLandingPage,
  isPlanningPage,
  isViewMode,
  isContactPage,
  isAccountPage,
  handleEdit,
  encodeStateToURL,
  setIsCaretakerModalOpen,
  setIsMobileMenuOpen,
  handleLogout
}) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  return (
    <header className="sticky top-0 z-50 w-full bg-header-bg text-header-text p-2 sm:p-4 flex justify-between items-center print:bg-white print:text-header-bg">
      <Link to="/" className="flex items-center">
        <BabyIcon className="w-12 h-12 sm:w-20 sm:h-20 mr-2 text-accent print:hidden" />
        <h1 className="text-3xl sm:text-4xl ml-2 sm:ml-8 font-bold font-title print:ml-0">GroepsPlanner</h1>
      </Link>
      <div className="flex items-center space-x-4 print:hidden">
        <div className="hidden sm:flex items-center space-x-4">
          {!currentUser && (
            <Link to={`/login?redirect=${encodeURIComponent(location.pathname)}`} className="bg-transparent text-header-text p-1 sm:p-2 rounded hover:text-accent flex flex-col items-center">
              <FontAwesomeIcon icon={faSignOutAlt} className="w-6 h-6 sm:w-8 sm:h-8 mb-2" />
              <span className="text-xs">Login</span>
            </Link>
          )}
          {currentUser && (isLandingPage || isPlanningPage || isViewMode || isContactPage || isAccountPage) && (
            <>
              {!isPlanningPage && (
                <Link to="/edit" className="bg-transparent text-header-text p-1 sm:p-2 rounded hover:text-accent flex flex-col items-center">
                  <FontAwesomeIcon icon={faCalendarAlt} className="w-6 h-6 sm:w-8 sm:h-8 mb-2" />
                  <span className="text-xs">Plan</span>
                </Link>
              )}
              {isPlanningPage && (
                <>
                  <button onClick={encodeStateToURL} className="bg-transparent text-header-text p-1 sm:p-2 rounded hover:text-accent flex flex-col items-center">
                    <FontAwesomeIcon icon={faShare} className="w-6 h-6 sm:w-8 sm:h-8 mb-2" />
                    <span className="text-xs">Deel</span>
                  </button>
                  <button onClick={() => setIsCaretakerModalOpen(true)} className="bg-transparent text-header-text p-1 sm:p-2 rounded hover:text-accent flex flex-col items-center">
                    <FontAwesomeIcon icon={faUsers} className="w-6 h-6 sm:w-8 sm:h-8 mb-2" />
                    <span className="text-xs">Medewerkers</span>
                  </button>
                </>
              )}
              {isViewMode && (
                <button onClick={handleEdit} className="bg-transparent text-header-text p-1 sm:p-2 rounded hover:text-accent flex flex-col items-center">
                  <FontAwesomeIcon icon={faPencilAlt} className="w-6 h-6 sm:w-8 sm:h-8 mb-2" />
                  <span className="text-xs">Bewerk</span>
                </button>
              )}
              <AccountMenu onLogout={handleLogout} />
            </>
          )}
        </div>
        <button 
          onClick={() => setIsMobileMenuOpen(true)} 
          className="sm:hidden text-2xl"
          aria-label="Open menu"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
    </header>
  );
};

export default Header;