import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-full mt-20">
        <div className="bg-group-bg p-8 rounded-lg shadow-lg max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-group-text">Oeps!</h2>
        <p className="text-group-text">De pagina die je probeert te bereiken bestaat niet.</p>
        <Link to="/" className="bg-accent text-header-text px-6 py-3 mt-8 rounded-lg text-lg hover:bg-opacity-80 inline-flex items-center">
          Ga terug naar de homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFound;