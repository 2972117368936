import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStreetView, faExclamationTriangle, faTimes, faPencilAlt, faHouseMedical, faRightLeft } from '@fortawesome/free-solid-svg-icons';

interface CaretakerExplanationModalProps {
  onClose: () => void;
}

const CaretakerExplanationModal: React.FC<CaretakerExplanationModalProps> = ({ onClose }) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-4 max-w-4xl w-full shadow-lg max-h-[90vh] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-bold mb-4 flex flex-col items-center text-center">
          <FontAwesomeIcon icon={faInfoCircle} className="text-accent mb-2" />
          Uitleg Medewerkers
        </h2>
        <hr className="border-t border-gray-300 my-4 w-1/4 max-w-[100px] mx-auto" />
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <h3 className="font-bold mb-2 text-center">Inzetbaarheid <span className="text-sm font-normal italic">(50%)</span></h3>
              <p>De inzetbaarheid van een medewerker wordt weergegeven als percentage. Een 50% inzetbaarheid betekent dat de medewerker voor de helft meetelt in de berekening van de BKR ten opzichte van een volledig inzetbare medewerker.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <h3 className="font-bold mb-2 text-center">
                <FontAwesomeIcon icon={faPencilAlt} className="text-gray-500 mr-1" /> Notitie
              </h3>
              <p>Klik op een medewerker om een notitie toe te voegen. Deze notitie wordt getoond in de groepenlijst. Dit kan bijvoorbeeld handig zijn om aan te geven dat een medewerker opent, sluit, of vroeg weg moet.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <h3 className="font-bold mb-2 text-center">
              <FontAwesomeIcon icon={faRightLeft} className="text-gray-500 mr-1" /> Medewerker Verplaatsen
              </h3>
              <p>Sleep een medewerker simpelweg op een andere groep, of klik op de medewerker, selecteer de nieuwe groep in de pop-up en klik op "Verplaats". Dit maakt het eenvoudig om snel aanpassingen in de groepsindeling te maken.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <h3 className="font-bold mb-2 text-center">
                Overtallig <FontAwesomeIcon icon={faStreetView} className="text-overstaffed ml-2" />
              </h3>
              <p>Het weghalen van deze medewerker zou de groep niet onderbezet maken. Als er meerdere icoontjes zichtbaar zijn, betekent dit dat ten minste één van deze medewerkers kan worden weggehaald zonder de BKR-norm te overschrijden.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <h3 className="font-bold mb-2 text-center">
                Ziek melden <FontAwesomeIcon icon={faHouseMedical} className="text-footer-bg ml-2" />
              </h3>
              <p>Klik op het ziekenhuisje om een medewerker ziek te melden. Dit zet de inzetbaarheid tijdelijk op 0%. Klik nogmaals om de oorspronkelijke inzetbaarheid te herstellen.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <h3 className="font-bold mb-2 text-center">
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500" /> Onbekend<br/> <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500" /> ZZP nodig<br/> <FontAwesomeIcon icon={faExclamationTriangle} className="text-green-500" /> ZZP bevestigd
              </h3>
              <p>Deze opties kunnen worden gebruikt om te zien hoe de planning eruit zou zien met een extra medewerker, zonder dat je al weet wie er ingepland zal worden.</p>
            </div>
          </div>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-accent text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors mt-4"
        >
          Sluiten
        </button>
      </div>
    </div>
  );
};

export default CaretakerExplanationModal;