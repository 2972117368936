export interface Theme {
  headerBackground: string;
  headerText: string;
  mainBackground: string;
  groupBackground: string;
  groupText: string;
  footerBackground: string;
  footerText: string;
  overstaffed: string;
  understaffed: string;
  properlystaffed: string;
  accent: string;
}

export const defaultTheme: Theme = {
  headerBackground: '#0b2e65',
  headerText: '#fbfffc',
  mainBackground: '#dbe8f0',
  groupBackground: '#f8faff',
  groupText: '#292d5a',
  footerBackground: '#6ce3c8',
  footerText: '#fbfffc',
  overstaffed: '#67e0b3',
  understaffed: '#ff4779',
  properlystaffed: '#67e0b3',
  accent: '#ff4779',
};

export function applyTheme(theme: Theme) {
  Object.entries(theme).forEach(([key, value]) => {
    document.documentElement.style.setProperty(`--color-${key}`, value);
  });
}