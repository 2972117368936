import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

interface AccountMenuProps {
  onLogout: () => void;
  isMobile?: boolean;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ onLogout, isMobile = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  const menuItems = [
    { icon: faCog, text: 'Account instellingen', link: '/account' },
    { icon: faSignOutAlt, text: 'Uitloggen', onClick: onLogout },
  ];

  if (isMobile) {
    return (
      <div className="w-full">
        <button
          onClick={toggleMenu}
          className="w-full text-left p-2 hover:bg-gray-200 transition-colors"
        >
          <FontAwesomeIcon icon={faUser} className="mr-2" />
          Account
        </button>
        {isOpen && (
          <div className="pl-4">
            {menuItems.map((item, index) => (
              item.link ? (
                <Link
                  key={index}
                  to={item.link}
                  className="block p-2 hover:bg-gray-200 transition-colors"
                >
                  <FontAwesomeIcon icon={item.icon} className="mr-2" />
                  {item.text}
                </Link>
              ) : (
                <button
                  key={index}
                  onClick={item.onClick}
                  className="w-full text-left p-2 hover:bg-gray-200 transition-colors"
                >
                  <FontAwesomeIcon icon={item.icon} className="mr-2" />
                  {item.text}
                </button>
              )
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={toggleMenu}
        className="bg-transparent text-header-text p-1 sm:p-2 rounded hover:text-accent flex flex-col items-center"
      >
        <FontAwesomeIcon icon={faUser} className="w-6 h-6 sm:w-8 sm:h-8 mb-2" />
        <span className="text-xs">Account</span>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
          {menuItems.map((item, index) => (
            item.link ? (
              <Link
                key={index}
                to={item.link}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
              >
                <FontAwesomeIcon icon={item.icon} className="mr-2" />
                {item.text}
              </Link>
            ) : (
              <button
                key={index}
                onClick={item.onClick}
                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
              >
                <FontAwesomeIcon icon={item.icon} className="mr-2" />
                {item.text}
              </button>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default AccountMenu;