import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

interface AdjustmentExplanationModalProps {
  onClose: () => void;
}

const AdjustmentExplanationModal: React.FC<AdjustmentExplanationModalProps> = ({ onClose }) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-4 max-w-4xl w-full shadow-lg max-h-[90vh] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-bold mb-4 flex flex-col items-center text-center">
          <FontAwesomeIcon icon={faInfoCircle} className="text-accent mb-2" />
          Uitleg Schuifindicator
        </h2>
        <hr className="border-t border-gray-300 my-4 w-1/4 max-w-[100px] mx-auto" />
        <p className="mb-4">Elke leeftijdsgroep heeft zijn eigen indicator. Het wijzigen van de samenstelling van de groep kan potentieel alle indicatoren beïnvloeden.</p>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <div className="bg-overstaffed text-white font-bold px-2 py-1 rounded inline-block w-1/6 text-xs text-center">+1</div>
              <p className="mt-2">Er is hier nog ruimte voor één extra kind in deze leeftijdsgroep zonder extra personeel.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <div className="bg-properlystaffed text-white font-bold px-2 py-1 rounded inline-block w-1/6 text-xs text-center">0</div>
              <p className="mt-2">De groep zit precies vol voor deze leeftijdscategorie volgens de BKR-norm.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <div className="bg-understaffed text-white font-bold px-2 py-1 rounded inline-block w-1/6 text-xs text-center">-1</div>
              <p className="mt-2">Er zijn te weinig medewerkers. Door één kind van deze leeftijd te verplaatsen, voldoet de groep weer aan de BKR-norm.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className="bg-gray-100 p-4 rounded-lg h-full">
              <div className="bg-understaffed bg-striped text-white font-bold px-2 py-1 rounded inline-block w-1/6 text-xs text-center">-1</div>
              <p className="mt-2">Er zijn te weinig medewerkers. Het verplaatsen van dit kind verbetert de situatie, maar is niet voldoende om volledig aan de BKR-norm te voldoen.</p>
            </div>
          </div>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-accent text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors mt-4"
        >
          Sluiten
        </button>
      </div>
    </div>
  );
};

export default AdjustmentExplanationModal;