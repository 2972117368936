import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';

interface ShareModalProps {
  shareUrl: string;
  planningTitle: string;
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ shareUrl, planningTitle, onClose }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const handleShare = async () => {
    if ('share' in navigator) {
      try {
        await navigator.share({
          title: `GroepsPlanner: ${planningTitle}`,
          text: 'Bekijk deze planning',
          url: shareUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 text-center">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full shadow-lg max-h-[90vh] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="text-2xl font-bold mb-4 flex flex-col items-center text-center">
          Delen
        </h2>
        <hr className="border-t border-gray-300 my-4 w-1/4 max-w-[100px] mx-auto" />
        <p className="mb-4">Hier is de link om te delen:</p>
        <div className="mb-4">
          <a href={shareUrl} target="_blank" rel="noopener noreferrer" className="text-accent hover:underline block break-all">
            {shareUrl}
          </a>
        </div>
        <div className="flex justify-between space-x-2 mb-2">
          {'share' in navigator && (
            <button
              onClick={handleShare}
              className="bg-accent text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors flex items-center justify-center flex-1"
            >
              <FontAwesomeIcon icon={faShare} className="mr-2" />
              Deel link
            </button>
          )}
          <button
            onClick={handleCopy}
            className="bg-accent text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors flex items-center justify-center flex-1"
          >
            <FontAwesomeIcon icon={faCopy} className="mr-2" />
            {copySuccess ? 'Gekopieerd!' : 'Kopiëren'}
          </button>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400 transition-colors"
        >
          Sluiten
        </button>
      </div>
    </div>
  );
};

export default ShareModal;