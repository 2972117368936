import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faShare, faUsers, faPencilAlt, faTimes, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';

interface MobileMenuProps {
  isLandingPage: boolean;
  isPlanningPage: boolean;
  isViewMode: boolean;
  isContactPage: boolean;
  isAccountPage: boolean;
  currentUser: any;
  handleEdit: () => void;
  encodeStateToURL: () => void;
  setIsCaretakerModalOpen: (isOpen: boolean) => void;
  onClose: () => void;
  handleLogout: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  isLandingPage,
  isPlanningPage,
  isViewMode,
  isContactPage,
  isAccountPage,
  currentUser,
  handleEdit,
  encodeStateToURL,
  setIsCaretakerModalOpen,
  onClose,
  handleLogout
}) => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex justify-end">
      <div className="w-64 bg-white h-full p-4 flex flex-col">
        <button onClick={onClose} className="self-end text-2xl mb-4">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {!currentUser && (
          <>
            <Link to="/login" className="mb-4 grid grid-cols-[20%_80%] items-center" onClick={onClose}>
              <FontAwesomeIcon icon={faSignOutAlt} className="justify-self-start ml-1" />
              <span>Login</span>
            </Link>
          </>
        )}
        {currentUser && (
          <>
            {!isPlanningPage && (
              <Link to="/edit" className="mb-4 grid grid-cols-[20%_80%] items-center" onClick={onClose}>
                <FontAwesomeIcon icon={faCalendarAlt} className="justify-self-start ml-1" />
                <span>Plan</span>
              </Link>
            )}
            {isPlanningPage && (
              <>
                <button onClick={() => { encodeStateToURL(); onClose(); }} className="mb-4 grid grid-cols-[20%_80%] items-center w-full text-left">
                  <FontAwesomeIcon icon={faShare} className="justify-self-start ml-1" />
                  <span>Deel</span>
                </button>
                <button onClick={() => { setIsCaretakerModalOpen(true); onClose(); }} className="mb-4 grid grid-cols-[20%_80%] items-center w-full text-left">
                  <FontAwesomeIcon icon={faUsers} className="justify-self-start ml-1" />
                  <span>Medewerkers</span>
                </button>
              </>
            )}
            {isViewMode && (
              <button onClick={() => { handleEdit(); onClose(); }} className="mb-4 grid grid-cols-[20%_80%] items-center w-full text-left">
                <FontAwesomeIcon icon={faPencilAlt} className="justify-self-start ml-1" />
                <span>Bewerk</span>
              </button>
            )}
            <Link to="/account" className="mb-4 grid grid-cols-[20%_80%] items-center" onClick={onClose}>
              <FontAwesomeIcon icon={faUser} className="justify-self-start ml-1" />
              <span>Account</span>
            </Link>
            <button onClick={() => { handleLogout(); onClose(); }} className="mb-4 grid grid-cols-[20%_80%] items-center w-full text-left">
              <FontAwesomeIcon icon={faSignOutAlt} className="justify-self-start ml-1" />
              <span>Logout</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;