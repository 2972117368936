import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import GroupView from './components/GroupView';
import CaretakerModal from './components/CaretakerModal';
import MobileMenu from './components/MobileMenu';
import LandingPage from './components/LandingPage';
import { determineRequiredCaretakers, staffingAdjustment } from './utils/DaycarePlanner';
import { Group, AgeRange, Caretaker, SharedData } from './Types';
import './PrintStyles.css';
import { v4 as uuidv4 } from 'uuid';
import { compressData, decompressData } from './utils/compression';
import { signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Contact from './components/Contact';
import ShareRedirect from './components/ShareRedirect';
import { createShortUrl } from './utils/urlShortener';
import CustomAlert from './components/CustomAlert';
import { collection, doc, setDoc, getDoc, deleteDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebaseConfig';
import ShareModal from './components/ShareModal';
import AccountPage from './components/AccountPage';
import { User } from './Types';
import NotFound from './components/NotFound';
import Header from './components/Header';

function App() {
  const [groups, setGroups] = useState<Group[]>(() => {
    const savedGroups = localStorage.getItem('groups');
    if (savedGroups) {
      const parsedGroups = JSON.parse(savedGroups);
      return parsedGroups.map((group: any) => ({
        ...group,
        title: group.title || `Groep ${parsedGroups.indexOf(group) + 1}`,
        temporaryCaretakers: Array.isArray(group.temporaryCaretakers) 
          ? group.temporaryCaretakers 
          : Array(group.temporaryCaretakers || 0).fill(null).map((_, index) => ({
              id: `temp-${group.id}-${index}`,
              name: 'Onbekend',
              availability: 100,
              isTemporary: true,
              type: 'unknown'
            })),
        caretakerIds: group.caretakerIds || [] // Add this line
      }));
    }
    return [];
  });

  const [caretakers, setCaretakers] = useState<Caretaker[]>(() => {
    const savedCaretakers = localStorage.getItem('caretakers');
    // console.log('Initial caretakers from localStorage:', savedCaretakers);
    return savedCaretakers ? JSON.parse(savedCaretakers) : [];
  });

  const [isCaretakerModalOpen, setIsCaretakerModalOpen] = useState(false);

  const [groupNotes, setGroupNotes] = useState<{ [key: string]: string }>(() => {
    const savedGroupNotes = localStorage.getItem('groupNotes');
    return savedGroupNotes ? JSON.parse(savedGroupNotes) : {};
  });

  const [planningTitle, setPlanningTitle] = useState<string>(() => {
    const savedTitle = localStorage.getItem('planningTitle');
    return savedTitle || 'Planning';
  });

  const [planningNotes, setPlanningNotes] = useState<string>(() => {
    const savedNotes = localStorage.getItem('planningNotes');
    return savedNotes || '';
  });

  useEffect(() => {
    // console.log('Saving caretakers to localStorage:', caretakers);
    localStorage.setItem('caretakers', JSON.stringify(caretakers));
  }, [caretakers]);

  useEffect(() => {
    localStorage.setItem('groups', JSON.stringify(groups));
  }, [groups]);

  useEffect(() => {
    localStorage.setItem('groupNotes', JSON.stringify(groupNotes));
  }, [groupNotes]);

  useEffect(() => {
    localStorage.setItem('planningTitle', planningTitle);
  }, [planningTitle]);

  useEffect(() => {
    localStorage.setItem('planningNotes', planningNotes);
  }, [planningNotes]);

  const addGroup = () => {
    const newGroupNumber = groups.length + 1;
    const newGroup: Group = {
      id: uuidv4(),
      title: `Groep ${newGroupNumber}`, // Set the title here
      ageRanges: ['0-1', '1-2', '2-3', '3-4'],
      children: {
        '0-1': 0,
        '1-2': 0,
        '2-3': 0,
        '3-4': 0,
      },
      caretakerNotes: {},
      temporaryCaretakers: [],
      caretakerIds: [] // Add this line
    };
    setGroups([...groups, newGroup]);
  };

  const deleteGroup = (groupId: string) => {
    setGroups(groups.filter(group => group.id !== groupId));
    const { [groupId]: _, ...restGroupNotes } = groupNotes;
    setGroupNotes(restGroupNotes);
  };

  const updateChildCount = (groupId: string, ageRange: AgeRange, count: number) => {
    setGroups(groups.map(group => 
      group.id === groupId ? {...group, children: {...group.children, [ageRange]: count}} : group
    ));
  };

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      loadCaretakersFromFirestore();
    }
  }, [currentUser]);

  const loadCaretakersFromFirestore = async () => {
    if (!currentUser) return;

    const caretakersRef = collection(db, 'caretakers');
    const q = query(caretakersRef, where('userId', '==', currentUser.uid));
    const querySnapshot = await getDocs(q);
    const loadedCaretakers: Caretaker[] = [];
    querySnapshot.forEach((doc) => {
      loadedCaretakers.push({ id: doc.id, ...doc.data() } as Caretaker);
    });
    setCaretakers(loadedCaretakers);
  };

  const syncCaretakerToFirestore = async (caretaker: Caretaker) => {
    if (!currentUser) return;

    if(caretaker.isTemporary) return;

    const caretakerToSave: any = {
      id: caretaker.id,
      name: caretaker.name,
      availability: caretaker.availability,
      userId: currentUser.uid,
      isSick: caretaker.isSick || false,
      isTemporary: caretaker.isTemporary || false,
      type: caretaker.type || null,
    };

    if (caretaker.originalAvailability !== undefined) {
      caretakerToSave.originalAvailability = caretaker.originalAvailability;
    }

    const caretakerRef = doc(db, 'caretakers', caretaker.id);
    await setDoc(caretakerRef, caretakerToSave, { merge: true });
  };

  const deleteCaretakerFromFirestore = async (caretakerId: string) => {
    if (!currentUser) return;

    const caretakerRef = doc(db, 'caretakers', caretakerId);
    await deleteDoc(caretakerRef);
  };

  const addCaretaker = async (name: string, availability: number) => {
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
    const newCaretaker: Caretaker = {
      id: uuidv4(),
      name: capitalizedName,
      availability,
    };
    setCaretakers([...caretakers, newCaretaker]);
    await syncCaretakerToFirestore(newCaretaker);
  };

  const removeCaretaker = async (caretakerId: string) => {
    setGroups(groups.map(group => ({
      ...group,
      caretakerIds: group.caretakerIds.filter(id => id !== caretakerId),
      caretakerNotes: Object.fromEntries(
        Object.entries(group.caretakerNotes).filter(([id]) => id !== caretakerId)
      )
    })));

    setCaretakers(caretakers.filter(caretaker => caretaker.id !== caretakerId));
    await deleteCaretakerFromFirestore(caretakerId);
    localStorage.setItem('caretakers', JSON.stringify(caretakers.filter(caretaker => caretaker.id !== caretakerId)));
  };

  const assignCaretaker = (caretakerId: string, groupId: string | null) => {
    setGroups(groups.map(group => {
      if (group.id === groupId) {
        return {
          ...group,
          caretakerIds: [...group.caretakerIds, caretakerId]
        };
      } else {
        return {
          ...group,
          caretakerIds: group.caretakerIds.filter(id => id !== caretakerId)
        };
      }
    }));
  };

  const unassignCaretaker = (caretakerId: string, groupId: string) => {
    if (caretakerId.startsWith('temp-')) {
      setGroups(groups.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            temporaryCaretakers: group.temporaryCaretakers.filter(c => c.id !== caretakerId)
          };
        }
        return group;
      }));
    } else {
      setGroups(groups.map(group => {
        if (group.id === groupId) {
          return {
            ...group,
            caretakerIds: group.caretakerIds.filter(id => id !== caretakerId),
            caretakerNotes: Object.fromEntries(
              Object.entries(group.caretakerNotes).filter(([id]) => id !== caretakerId)
            )
          };
        }
        return group;
      }));
    }
  };

  const updateCaretakerAvailability = async (id: string, availability: number) => {
    const updatedCaretakers = caretakers.map(caretaker => 
      caretaker.id === id ? {...caretaker, availability} : caretaker
    );
    setCaretakers(updatedCaretakers);
    localStorage.setItem('caretakers', JSON.stringify(updatedCaretakers));
    const updatedCaretaker = updatedCaretakers.find(c => c.id === id);
    if (updatedCaretaker) {
      await syncCaretakerToFirestore(updatedCaretaker);
    }
  };

  const getGroupStatus = (group: Group, caretakersToUse: Caretaker[]) => {
    const groupCaretakers = caretakersToUse.filter(c => group.caretakerIds ? group.caretakerIds.includes(c.id) : false);
    const allCaretakers = [...groupCaretakers, ...(Array.isArray(group.temporaryCaretakers) ? group.temporaryCaretakers : [])];
    return staffingAdjustment(group, allCaretakers);
  };

  const updateGroupTitle = (groupId: string, title: string) => {
    setGroups(groups.map(group => 
      group.id === groupId ? { ...group, title } : group
    ));
  };

  const updateGroupNotes = (groupId: string, notes: string) => {
    setGroupNotes({ ...groupNotes, [groupId]: notes.trim().length === 0 ? '' : notes });
  };

  const getTotalChildCount = (group: Group): number => {
    return Object.values(group.children).reduce((sum, count) => sum + (count || 0), 0);
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsCaretakerModalOpen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const [viewData, setViewData] = useState<SharedData | null>(null);

  const [viewError, setViewError] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const isPlanningPage = location.pathname === '/edit';
  const isContactPage = location.pathname === '/contact';
  const isViewMode = location.pathname.startsWith('/view');
  const isAccountPage = location.pathname === '/account';

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'view' && pathParts[2]) {
      try {
        const decodedData = decompressData(pathParts[2]);
        if (decodedData && decodedData.groups && Array.isArray(decodedData.groups)) {
          const processedGroups = decodedData.groups.map((group: any) => {
            if (typeof group.temporaryCaretakers === 'number') {
              // Convert old format to new format
              const tempCaretakers: Caretaker[] = Array(group.temporaryCaretakers).fill(null).map((_, index) => ({
                id: `temp-${group.id}-${index}`,
                name: 'Onbekend',
                assignedGroupId: group.id,
                availability: 100,
                isTemporary: true,
                type: 'unknown'
              }));
              return {
                ...group,
                temporaryCaretakers: tempCaretakers
              };
            }
            return group;
          });

          setViewData({
            groups: processedGroups,
            caretakers: decodedData.caretakers,
            groupTitles: decodedData.groupTitles,
            groupNotes: decodedData.groupNotes,
            planningTitle: decodedData.planningTitle || 'Planning', // Provide a default value
            planningNotes: decodedData.planningNotes || '', // Provide a default value
          });
          setViewError(null); // Clear any previous errors
          // If the user came from a TinyURL, update the URL to the full version
          if (document.referrer.includes('tinyurl.com')) {
            window.history.replaceState({}, '', `/view/${pathParts[2]}`);
          }
        } else {
          console.error('Invalid data structure in URL');
          setViewError('Er is iets misgegaan. De gegevens in de URL zijn ongeldig.');
          setViewData(null);
        }
      } catch (error) {
        console.error('Error decoding view data:', error);
        setViewError('Er is iets misgegaan bij het laden van de gegevens.');
        setViewData(null);
      }
    }
  }, [location]);

  const [alertConfig, setAlertConfig] = useState<{
    message: string;
    link?: string;
    onConfirm: () => void;
    onCancel?: () => void;
  } | null>(null);

  const [isAnotherModalOpen, setIsAnotherModalOpen] = useState(false);

  const [shareModalUrl, setShareModalUrl] = useState<string | null>(null);

  const encodeStateToURL = async () => {
    const stateToEncode: SharedData = { groups, caretakers, groupTitles: {}, groupNotes, planningTitle, planningNotes };
    const compressedData = compressData(stateToEncode);

    try {
      const shortcode = await createShortUrl(compressedData);
      const shareUrl = `${window.location.origin}/share/${shortcode}`;
      setShareModalUrl(shareUrl);
    } catch (error) {
      console.error('Error creating short URL:', error);
      const longUrl = `${window.location.origin}/view/${compressedData}`;
      setShareModalUrl(longUrl);
    }
  };

  const handleEdit = () => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
    setAlertConfig({
      message: 'Weet je zeker dat je je huidige planning wilt overschrijven met deze gedeelde planning?',
      onConfirm: async () => {
        if (viewData) {
          setGroups(viewData.groups);
          setGroupNotes(viewData.groupNotes);
          setPlanningTitle(viewData.planningTitle);
          
          // Merge caretakers, keeping all existing ones and adding new ones
          const mergedCaretakers = [...caretakers];
          for (const newCaretaker of viewData.caretakers) {
            const existingCaretaker = mergedCaretakers.find(c => 
              c.name.toLowerCase() === newCaretaker.name.toLowerCase() && 
              c.availability === newCaretaker.availability
            );
            if (!existingCaretaker && mergedCaretakers.some(c => c.name.toLowerCase() === newCaretaker.name.toLowerCase())) {
              newCaretaker.name = `${newCaretaker.name} (${newCaretaker.availability})`;
            }
            if (!existingCaretaker) {
              mergedCaretakers.push(newCaretaker);
              await syncCaretakerToFirestore(newCaretaker);
            } else {
              Object.assign(existingCaretaker, newCaretaker);
              await syncCaretakerToFirestore(existingCaretaker);
            }
          }
          setCaretakers(mergedCaretakers);
        }
        navigate('/edit');
        setAlertConfig(null);
      },
      onCancel: () => setAlertConfig(null)
    });
  };

  const reorderCaretakers = (reorderedCaretakers: Caretaker[]) => {
    setCaretakers(reorderedCaretakers);
  };

  const removeAllCaretakers = async () => {
    // Unassign all caretakers from their groups
    setGroups(groups.map(group => ({
      ...group,
      caretakerNotes: {}
    })));

    // Remove all caretakers from the state
    setCaretakers([]);

    // Delete all caretakers from Firestore
    for (const caretaker of caretakers) {
      await deleteCaretakerFromFirestore(caretaker.id);
    }

    // Update local storage
    localStorage.setItem('caretakers', JSON.stringify([]));
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const addTemporaryCaretaker = (groupId: string, type: 'unknown' | 'zzp-needed' | 'zzp-confirmed') => {
    setGroups(groups.map(group => {
      if (group.id === groupId) {
        const newTempCaretaker: Caretaker = {
          id: `temp-${groupId}-${Date.now()}`,
          name: type === 'unknown' ? 'Onbekend' : type === 'zzp-needed' ? 'ZZP nodig' : 'ZZP bevestigd',
          availability: type === 'zzp-needed' ? 0 : 100,
          isTemporary: true,
          type: type
        };
        return {
          ...group,
          temporaryCaretakers: [...(group.temporaryCaretakers || []), newTempCaretaker]
        };
      }
      return group;
    }));
  };

  const showConfirmDialog = (message: string, onConfirm: () => void) => {
    setIsAnotherModalOpen(true);
    setAlertConfig({
      message,
      onConfirm: () => {
        onConfirm();
        setAlertConfig(null);
        setIsAnotherModalOpen(false);
      },
      onCancel: () => {
        setAlertConfig(null);
        setIsAnotherModalOpen(false);
      }
    });
  };
  const showAlert = (message: string, onConfirm: () => void) => {
    setAlertConfig({
      message,
      onConfirm: () => {
        onConfirm();
        setAlertConfig(null);
      },
    });
  };

  const updateCaretakerNote = (groupId: string, caretakerId: string, note: string) => {
    setGroups(groups.map(group => 
      group.id === groupId 
        ? { ...group, caretakerNotes: { ...group.caretakerNotes, [caretakerId]: note.trim().length === 0 ? '' : note } }
        : group
    ));
  };

  const updatePlanningTitle = (title: string) => {
    setPlanningTitle(title.trim().length === 0 ? '' : title);
  };

  const updatePlanningNotes = (notes: string) => {
    setPlanningNotes(notes.trim().length === 0 ? '' : notes);
  };

  const toggleCaretakerSickStatus = async (caretakerId: string) => {
    const updatedCaretakers = caretakers.map(caretaker => {
      if (caretaker.id === caretakerId) {
        if (caretaker.isSick) {
          // Restore original availability
          return {
            ...caretaker,
            isSick: false,
            availability: caretaker.originalAvailability || caretaker.availability,
            originalAvailability: undefined // Clear the original availability
          };
        } else {
          // Mark as sick and set availability to 0%
          return {
            ...caretaker,
            isSick: true,
            originalAvailability: caretaker.availability,
            availability: 0
          };
        }
      }
      return caretaker;
    });

    setCaretakers(updatedCaretakers);
    localStorage.setItem('caretakers', JSON.stringify(updatedCaretakers));

    // Sync the updated caretaker to Firestore
    const updatedCaretaker = updatedCaretakers.find(c => c.id === caretakerId);
    if (updatedCaretaker) {
      await syncCaretakerToFirestore(updatedCaretaker);
    }
  };
  // Reordering within the same group
  const handleReorderCaretakers = (groupId: string, oldIndex: number, newIndex: number) => {
    if (!groupId) {
      return;
    }

    setGroups(prevGroups => {
      return prevGroups.map(group => {
        if (group.id === groupId) {
          // Create a new array from the existing caretakerIds
          const newCaretakerIds = Array.from(group.caretakerIds);
          // Remove the caretaker ID from the old index
          const [reorderedId] = newCaretakerIds.splice(oldIndex, 1);
          // Insert the removed caretaker ID at the new index
          newCaretakerIds.splice(newIndex, 0, reorderedId);
          // Return a new group object with the updated caretakerIds
          return { ...group, caretakerIds: newCaretakerIds };
        }
        return group;
      });
    });
  };

  const moveCaretaker = (caretakerId: string, newGroupId: string, newIndex: number) => {
    setGroups(prevGroups => {
      const updatedGroups = prevGroups.map(group => {
        if (group.id === newGroupId) {
          // Add caretaker to new group at the specified index
          const newCaretakerIds = [...group.caretakerIds];
          newCaretakerIds.splice(newIndex, 0, caretakerId);
          return {
            ...group,
            caretakerIds: newCaretakerIds,
            caretakerNotes: {
              ...group.caretakerNotes,
              // Move the note to the new group if it exists
              [caretakerId]: prevGroups.find(g => g.caretakerIds.includes(caretakerId))?.caretakerNotes[caretakerId] || ''
            }
          };
        } else {
          // Remove caretaker from old group
          return {
            ...group,
            caretakerIds: group.caretakerIds.filter(id => id !== caretakerId),
            caretakerNotes: Object.fromEntries(
              Object.entries(group.caretakerNotes).filter(([id]) => id !== caretakerId)
            )
          };
        }
      });
      return updatedGroups;
    });
  };

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data() as User);
        } else {
          // If the user document doesn't exist, create it with 'free' permissions
          const newUser: User = {
            id: currentUser.uid,
            email: currentUser.email || '',
            permissionLevel: 0 // 0 represents 'free'
          };
          await setDoc(userRef, newUser);
          setUser(newUser);
        }
      } else {
        setUser(null);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const deleteAllGroups = () => {
    setGroups([]);
    setGroupNotes({});
  };

  const resetAllGroups = () => {
    setGroups(groups.map(group => ({
      ...group,
      children: {
        '0-1': 0,
        '1-2': 0,
        '2-3': 0,
        '3-4': 0,
      },
      caretakerIds: [],
      temporaryCaretakers: [],
      caretakerNotes: {},
    })));
  };

  return (
    <AuthProvider>
      <div className="App min-h-screen min-w-[320px] print:bg-white print:h-auto flex flex-col">
        <Header 
          isLandingPage={isLandingPage}
          isPlanningPage={isPlanningPage}
          isViewMode={isViewMode}
          isContactPage={isContactPage}
          isAccountPage={isAccountPage}
          handleEdit={handleEdit}
          encodeStateToURL={encodeStateToURL}
          setIsCaretakerModalOpen={setIsCaretakerModalOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          handleLogout={handleLogout}
        />
        <div className="flex-grow p-0 sm:p-0 text-center bg-main-bg print:p-0 relative">
          <div className="pb-24">
            <Routes>
              <Route 
                path="/" 
                element={
                  currentUser ? (
                    <Navigate to="/edit" replace />
                  ) : (
                    <LandingPage currentUser={currentUser} handleLogout={handleLogout} />
                  )
                } 
              />
              <Route path="/login" element={<Login showAlert={showAlert} />} />
              <Route path="/contact" element={<Contact />} />
              <Route
                path="/edit"
                element={
                  <ProtectedRoute>
                    <div className="px-4">
                      <GroupView
                        groups={groups}
                        caretakers={caretakers}
                        groupNotes={groupNotes}
                        getGroupStatus={(group, caretakers) => getGroupStatus(group, caretakers)}
                        getTotalChildCount={getTotalChildCount}
                        updateChildCount={updateChildCount}
                        deleteGroup={deleteGroup}
                        updateGroupTitle={updateGroupTitle}
                        updateGroupNotes={updateGroupNotes}
                        assignCaretaker={assignCaretaker}
                        unassignCaretaker={unassignCaretaker}
                        addGroup={addGroup}
                        determineRequiredCaretakers={determineRequiredCaretakers}
                        addTemporaryCaretaker={addTemporaryCaretaker}
                        isLandingPage={false}
                        updateCaretakerNote={updateCaretakerNote}
                        planningTitle={planningTitle}
                        updatePlanningTitle={updatePlanningTitle}
                        planningNotes={planningNotes}
                        updatePlanningNotes={updatePlanningNotes}
                        toggleCaretakerSickStatus={toggleCaretakerSickStatus}
                        onReorderCaretakers={handleReorderCaretakers}
                        moveCaretaker={moveCaretaker}
                        showAlert={showAlert}
                        deleteAllGroups={deleteAllGroups}
                        resetAllGroups={resetAllGroups}
                      />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route path="/share/:shortcode" element={<ShareRedirect />} />
              <Route
                path="/view/:encodedData"
                element={
                  viewError ? (
                    <div className="flex items-center justify-center h-full mt-20">
                      <div className="bg-group-bg p-8 rounded-lg shadow-lg max-w-md">
                        <h2 className="text-2xl font-bold mb-4 text-group-text">Oeps!</h2>
                        <p className="text-group-text">{viewError}</p>
                      </div>
                    </div>
                  ) : viewData ? (
                    <div className="px-4">
                      <GroupView
                        groups={viewData.groups}
                        caretakers={viewData.caretakers}
                        groupNotes={viewData.groupNotes}
                        getGroupStatus={(group, caretakers) => getGroupStatus(group, caretakers)}
                        getTotalChildCount={getTotalChildCount}
                        updateChildCount={() => {}}
                        deleteGroup={() => {}}
                        updateGroupTitle={() => {}}
                        updateGroupNotes={() => {}}
                        assignCaretaker={() => {}}
                        unassignCaretaker={unassignCaretaker}
                        addGroup={() => {}}
                        determineRequiredCaretakers={determineRequiredCaretakers}
                        addTemporaryCaretaker={() => {}}
                        readOnly={true}
                        isLandingPage={false}
                        updateCaretakerNote={() => {}} // No-op function for read-only view
                        planningTitle={viewData.planningTitle}
                        updatePlanningTitle={() => {}}  // No-op function for read-only view
                        planningNotes={viewData.planningNotes}
                        updatePlanningNotes={() => {}}  // No-op function for read-only view
                        toggleCaretakerSickStatus={toggleCaretakerSickStatus}
                        onReorderCaretakers={handleReorderCaretakers}
                        moveCaretaker={moveCaretaker}
                        showAlert={showAlert}
                        deleteAllGroups={()=>{}}
                        resetAllGroups={()=>{}}
                      />
                    </div>
                  ) : (
                    <div>Aan het laden...</div>
                  )
                }
              />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <AccountPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>

          {/* Curved separator */}
          <div className="absolute bottom-[-1px] left-0 w-full overflow-hidden print:hidden z-10" style={{ height: '50px' }}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
              <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: 'var(--color-footerBackground)' }}></path>
            </svg>
          </div>
        </div>
        <footer className="w-full bg-footer-bg py-4 sm:py-10 text-footer-text p-2 sm:p-4 text-center print:bg-white print:text-footer-bg print:py-4 relative z-20 min-h-[100px]">
          <p>&copy; 2024 GroepsPlanner. Alle rechten voorbehouden.</p>
        </footer>
      </div>

      {isMobileMenuOpen && (
        <MobileMenu
          isLandingPage={isLandingPage}
          isPlanningPage={isPlanningPage}
          isViewMode={isViewMode}
          isContactPage={isContactPage}
          isAccountPage={isAccountPage}
          currentUser={currentUser}
          handleEdit={handleEdit}
          encodeStateToURL={encodeStateToURL}
          setIsCaretakerModalOpen={setIsCaretakerModalOpen}
          onClose={() => setIsMobileMenuOpen(false)}
          handleLogout={handleLogout}
        />
      )}

      {isCaretakerModalOpen && (
        <CaretakerModal
          caretakers={caretakers}
          onClose={() => setIsCaretakerModalOpen(false)}
          onAddCaretaker={addCaretaker}
          onRemoveCaretaker={removeCaretaker}
          onUpdateAvailability={updateCaretakerAvailability}
          onReorderCaretakers={reorderCaretakers}
          onRemoveAllCaretakers={removeAllCaretakers}
          showConfirmDialog={showConfirmDialog}
          isAnotherModalOpen={isAnotherModalOpen}
          showAlert={showAlert}
        />
      )}

      {alertConfig && (
        <CustomAlert
          message={alertConfig.message}
          link={alertConfig.link}
          onConfirm={alertConfig.onConfirm}
          onCancel={alertConfig.onCancel}
        />
      )}

      {shareModalUrl && (
        <ShareModal
          shareUrl={shareModalUrl}
          planningTitle={planningTitle}
          onClose={() => setShareModalUrl(null)}
        />
      )}
    </AuthProvider>
  );
}

export default App;