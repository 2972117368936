import { collection, doc, setDoc, getDoc, query, where, getDocs, deleteDoc, Timestamp } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { db } from '../firebaseConfig';

const SHORTCODES_COLLECTION = 'shortcodes';

interface ShortcodeData {
  compressedData: string;
  createdAt: Timestamp;
}

export async function createShortUrl(compressedData: string): Promise<string> {
  const shortcode = nanoid(8); // Generate a random 8-character shortcode
  const shortcodeRef = doc(db, SHORTCODES_COLLECTION, shortcode);

  await setDoc(shortcodeRef, {
    compressedData,
    createdAt: Timestamp.now()
  });

  return shortcode;
}

export async function getCompressedData(shortcode: string): Promise<string | null> {
  console.log('Getting compressed data for shortcode:', shortcode);
  const shortcodeRef = doc(db, SHORTCODES_COLLECTION, shortcode);
  const shortcodeSnap = await getDoc(shortcodeRef);

  if (shortcodeSnap.exists()) {
    const data = shortcodeSnap.data() as ShortcodeData;
    return data.compressedData;
  }

  console.log('No data found for shortcode:', shortcode);
  return null;
}

export async function deleteExpiredShortcodes(): Promise<void> {
  const thirtyDaysAgo = Timestamp.fromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const expiredShortcodesQuery = query(
    collection(db, SHORTCODES_COLLECTION),
    where('createdAt', '<', thirtyDaysAgo)
  );

  const expiredShortcodesSnap = await getDocs(expiredShortcodesQuery);
  const deletePromises = expiredShortcodesSnap.docs.map(doc => deleteDoc(doc.ref));
  await Promise.all(deletePromises);
}

// This function can be called periodically (e.g., once a day) to clean up expired shortcodes
export async function cleanupExpiredShortcodes(): Promise<void> {
  try {
    await deleteExpiredShortcodes();
    console.log('Expired shortcodes cleaned up successfully');
  } catch (error) {
    console.error('Error cleaning up expired shortcodes:', error);
  }
}