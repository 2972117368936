import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Group, Caretaker, AgeRange } from '../Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faChild, faUser, faPlus, faMinus, faStreetView, faInfoCircle, faHouseMedical, faCheckCircle, faTrash, faRedo, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import BKRInfoModal from './BKRInfoModal';
import AdjustmentExplanationModal from './AdjustmentExplanationModal';
import CaretakerExplanationModal from './CaretakerExplanationModal';
import CaretakerNoteModal from './CaretakerNoteModal';
import { useAuth } from '../contexts/AuthContext';

interface GroupViewProps {
  groups: Group[];
  caretakers: Caretaker[];
  groupNotes: { [key: string]: string };
  getGroupStatus: (group: any, caretakersToUse: Caretaker[]) => { status: string, adjustments: { [key: string]: { count: number; solvesProblem: boolean } } };
  getTotalChildCount: (group: any) => number;
  determineRequiredCaretakers: (group: any) => number;
  updateGroupTitle: (groupId: string, title: string) => void;
  updateGroupNotes: (groupId: string, notes: string) => void;
  updateChildCount: (groupId: string, ageRange: AgeRange, count: number) => void;
  deleteGroup: (groupId: string) => void;
  assignCaretaker: (caretakerId: string, groupId: string) => void;
  unassignCaretaker: (caretakerId: string, groupId: string) => void;
  addGroup: () => void;
  addTemporaryCaretaker: (groupId: string, type: 'unknown' | 'zzp-needed' | 'zzp-confirmed') => void;
  readOnly?: boolean;
  isLandingPage?: boolean;
  updateCaretakerNote: (groupId: string, caretakerId: string, note: string) => void;
  planningTitle: string;
  updatePlanningTitle: (title: string) => void;
  planningNotes: string;
  updatePlanningNotes: (notes: string) => void;
  toggleCaretakerSickStatus: (caretakerId: string) => void;
  onReorderCaretakers: (groupId: string, oldIndex: number, newIndex: number) => void;
  moveCaretaker: (caretakerId: string, newGroupId: string, newIndex: number) => void;
  showAlert: (message: string, onConfirm: () => void) => void;
  deleteAllGroups: () => void;
  resetAllGroups: () => void;
}

const GroupView: React.FC<GroupViewProps> = ({
  groups,
  caretakers,
  groupNotes,
  getGroupStatus,
  getTotalChildCount,
  determineRequiredCaretakers,
  updateGroupTitle,
  updateGroupNotes,
  updateChildCount,
  deleteGroup,
  assignCaretaker,
  unassignCaretaker,
  addGroup,
  addTemporaryCaretaker,
  readOnly = false,
  isLandingPage = false,
  updateCaretakerNote,
  planningTitle,
  updatePlanningTitle,
  planningNotes,
  updatePlanningNotes,
  toggleCaretakerSickStatus,
  onReorderCaretakers,
  moveCaretaker,
  showAlert,
  deleteAllGroups,
  resetAllGroups,
}) => {
  const [bkrInfoModalData, setBkrInfoModalData] = useState<Group | null>(null);
  const [showAdjustmentExplanation, setShowAdjustmentExplanation] = useState(false);
  const [showCaretakerExplanation, setShowCaretakerExplanation] = useState(false);
  const [caretakerToEdit, setCaretakerToEdit] = useState<{ groupId: string; caretaker: Caretaker } | null>(null);
  const { user } = useAuth();

  const canAddGroup = () => {
    if (!user) return false;
    if (user.permissionLevel === 0) return false;
    if (user.permissionLevel === 1) return groups.length < 4;
    return true;
  };

  const canAddCaretaker = () => {
    if (!user) return false;
    if (user.permissionLevel === 0) return false;
    if (user.permissionLevel === 1) return caretakers.length < 8;
    return true;
  };

  const handleAddGroup = () => {
    if (!canAddGroup()) {
      showAlert('Je hebt de maximale limiet voor groepen bereikt. Upgrade je account om meer groepen toe te voegen.', () => {});
      return;
    }
    addGroup();
  };

  const canRemoveCaretaker = (group: Group, caretakers: Caretaker[], caretakerToRemove: Caretaker): boolean => {
    if (caretakerToRemove.availability === 0) {
      return true;
    }

    const { status } = getGroupStatus(group, caretakers);
    if (status === 'understaffed' || status === 'invalid') {
      return false;
    }
    
    const caretakersAfterRemoval = caretakers.filter(c => 
      c.id !== caretakerToRemove.id && group.caretakerIds.includes(c.id)
    );

    const groupAfterRemoval = {
      ...group,
      caretakerIds: group.caretakerIds.filter(id => id !== caretakerToRemove.id),
      temporaryCaretakers: caretakerToRemove.isTemporary
        ? group.temporaryCaretakers.filter(tc => tc.id !== caretakerToRemove.id)
        : group.temporaryCaretakers
    };

    const { status: statusAfterRemoval } = getGroupStatus(groupAfterRemoval, caretakersAfterRemoval);
    return statusAfterRemoval !== 'understaffed' && statusAfterRemoval !== 'invalid';
  };

  const onDragEnd = (result: DropResult) => {
    if (readOnly) return; // Add this line to prevent any drag operations in readonly mode

    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // If the caretaker was dropped in a different group
    if (source.droppableId !== destination.droppableId) {
      const sourceGroupId = source.droppableId;
      const destGroupId = destination.droppableId;
      const newIndex = destination.index;
      const caretakerId = result.draggableId;

      // Call the moveCaretaker function to handle the group change
      moveCaretaker(caretakerId, destGroupId, newIndex);
    } else {
      // If the caretaker was reordered within the same group
      const groupId = source.droppableId;
      const oldIndex = source.index;
      const newIndex = destination.index;

      // Call onReorderCaretakers with the necessary information
      onReorderCaretakers(groupId, oldIndex, newIndex);
    }
  };

  const handleUpdateGroupNotes = (groupId: string, notes: string) => {
    updateGroupNotes(groupId, notes.trim().length === 0 ? '' : notes);
  };

  const handleUpdateCaretakerNote = (groupId: string, caretakerId: string, note: string) => {
    updateCaretakerNote(groupId, caretakerId, note.trim().length === 0 ? '' : note);
  };

  const handleUpdatePlanningNotes = (notes: string) => {
    updatePlanningNotes(notes.trim().length === 0 ? '' : notes);
  };

  const resetChildCount = (groupId: string, ageRange: AgeRange) => {
    updateChildCount(groupId, ageRange, 0);
  };

  if (user?.permissionLevel === 0) {
    return (
      <div className="text-center mt-10">
        <h2 className="text-2xl font-bold mb-4">Gratis account</h2>
        <p>Upgrade je account om planningen te maken.</p>
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={`flex flex-wrap ${isLandingPage ? 'justify-start' : 'justify-center'} gap-5 mt-5 print:block print:mt-0`}>
        {!readOnly && !isLandingPage && (
          <div className="w-full flex justify-center items-center mb-4">
            <button
              onClick={() => showAlert('Weet je zeker dat je alle groepen leeg wilt maken?', resetAllGroups)}
              className="p-2 text-group-text rounded hover:text-accent transition-colors"
              aria-label="Reset alle groepen"
            >
              <FontAwesomeIcon icon={faRedo} />
            </button>
            <button
              onClick={() => showAlert('Weet je zeker dat je alle groepen wilt verwijderen?', deleteAllGroups)}
              className="mr-4 p-2 text-group-text rounded hover:text-accent transition-colors"
              aria-label="Verwijder alle groepen"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        )}
        {!isLandingPage && (readOnly ? (
          <h1 className="text-3xl font-bold mb-6 text-center w-full">{planningTitle}</h1>
        ) : (
          <input
            type="text"
            className="text-3xl font-bold mb-6 text-center w-full bg-transparent border-none"
            style={{ height: '40px' }}
            value={planningTitle}
            onChange={(e) => updatePlanningTitle(e.target.value)}
            placeholder="Planning Titel"
          />
        ))}
        {groups.map(group => {
          const { status, adjustments } = getGroupStatus(group, caretakers);
          const unassignedCaretakers = caretakers.filter(c => !groups.some(g => g.caretakerIds ? g.caretakerIds.includes(c.id) : false));
          const groupCaretakers = group.caretakerIds ? group.caretakerIds.map(id => caretakers.find(c => c.id === id)).filter(Boolean) as Caretaker[] : [];
          const temporaryCaretakers = group.temporaryCaretakers || 0;
          const totalChildCount = getTotalChildCount(group);
          const requiredCaretakers = determineRequiredCaretakers(group);

          // Sort caretakers: non-sick first, then sick
          const sortedGroupCaretakers = [...groupCaretakers].sort((a, b) => {
            if (a.isSick === b.isSick) return 0;
            return a.isSick ? 1 : -1;
          });

          return (
            <div key={group.id} className={`bg-group-bg relative ${isLandingPage ? 'w-60' : 'w-full md:w-80 lg:w-80'} p-4 rounded-lg border-[3px] group-container print:w-full print:mb-8 ${
              status === 'overstaffed' ? 'border-overstaffed' :
              status === 'understaffed' ? 'border-understaffed' :
              status === 'invalid' ? 'border-understaffed' :
              'border-properlystaffed'
            }`}>
              {readOnly ? (
                <h2 className="w-full text-lg font-bold mb-2 text-center">
                  {group.title}
                </h2>
              ) : (
                <input
                  type="text"
                  className="w-full text-lg font-bold mb-2 bg-transparent border-none text-center"
                  value={group.title}
                  placeholder="Groep"
                  onChange={(e) => updateGroupTitle(group.id, e.target.value)}
                />
              )}
              <div 
                className="flex items-center justify-center cursor-pointer group relative"
                onClick={() => setBkrInfoModalData(group)}
              >
                <p 
                  id="child-caretaker" 
                  className="italic text-sm"
                >
                  <FontAwesomeIcon icon={faChild} className='text-gray-400'/> {totalChildCount} <FontAwesomeIcon icon={faUser} className='ml-2 text-gray-400' /> {Math.ceil(requiredCaretakers)}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ml-3 mt-[4px] text-gray-300 group-hover:text-accent transition-colors absolute"
                  />
                </p>
              </div>
              <div className="w-1/6 border-t border-gray-300 mt-3 mb-5 mx-auto"></div>

              {!readOnly && (
                <button onClick={() => deleteGroup(group.id)} className="absolute top-2 right-2 text-gray-500 hover:text-red-500" aria-label="Verwijder groep">
                  ×
                </button>
              )}
              <div className="space-y-2 relative">
                {group.ageRanges.map((ageRange, index) => (
                  <div key={ageRange} className="flex items-center justify-between">
                    <div className={`flex items-center w-1/6 ${readOnly ? 'justify-center' : ''}`}>
                    {!readOnly && (
                      <button
                              className="text-gray-400 hover:text-gray-600 transition-colors w-5/12 text-left"
                              onClick={() => resetChildCount(group.id, ageRange)}
                              aria-label={`Reset ${ageRange} count to 0`}
                            >
                              <FontAwesomeIcon icon={faArrowRotateLeft} className="text-xs" />
                      </button>
                    )}

                      <label className="text-center text-sm font-bold">{ageRange}</label>
                    </div>
                    <div className="flex items-center w-1/2">
                      {!readOnly && (
                        <>
                          <button
                            className="px-2 py-1 bg-gray-200 rounded-l"
                            onClick={() => updateChildCount(group.id, ageRange, Math.max(0, (group.children[ageRange] || 0) - 1))}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                        </>
                      )}
                      {readOnly ? (
                        <span className="w-full px-2 py-1 border rounded bg-gray-100 font-bold text-center">
                          {group.children[ageRange] || 0}
                        </span>
                      ) : (
                        <select
                          className="w-full px-2 py-1 border-t border-b text-center"
                          value={group.children[ageRange] || 0}
                          onChange={(e) => updateChildCount(group.id, ageRange, parseInt(e.target.value))}
                        >
                          {[...Array(ageRange === '0-1' ? 13 : 17)].map((_, i) => (
                            <option key={i} value={i}>{i}</option>
                          ))}
                        </select>
                      )}
                      {!readOnly && (
                        <button
                          className="px-2 py-1 bg-gray-200 rounded-r"
                          onClick={() => updateChildCount(group.id, ageRange, Math.min(ageRange === '0-1' ? 12 : 16, (group.children[ageRange] || 0) + 1))}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      )}
                    </div>
                    <span 
                      id={`adjustment-${ageRange}`} 
                      className={`w-1/6 text-xs font-bold px-2 py-1 rounded ml-2 cursor-pointer ${
                        status === 'overstaffed' ? 'bg-overstaffed text-white' :
                        status === 'understaffed' ? 'bg-understaffed text-white' :
                        status === 'invalid' ? 'bg-understaffed text-white' :
                        'bg-properlystaffed text-white'
                      } ${
                        (status === 'understaffed' && !adjustments[ageRange].solvesProblem && adjustments[ageRange].count !== 0)
                          ? 'bg-striped text-opacity-85' 
                          : ''
                      }`}
                      onClick={() => setShowAdjustmentExplanation(true)}
                    >
                      {status !== 'invalid' && adjustments[ageRange].count !== 0 ? 
                        (adjustments[ageRange].count > 0 ? '+' : '') + adjustments[ageRange].count :
                        '0'
                      }
                    </span>
                  </div>
                ))}

                {/* New row for the info icon */}
                <div className="flex items-center justify-between">
                  <div className="w-1/6"></div>
                  <div className="w-1/2"></div>
                  <div className="w-1/6 flex justify-center">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="cursor-pointer text-gray-300 hover:text-accent transition-colors duration-200"
                      onClick={() => setShowAdjustmentExplanation(true)}
                    />
                  </div>
                </div>
              </div>
              
              <div className="-mt-1 pt-2 border-t border-gray-400 max-w-[60px] mx-auto">
                <div className="flex items-center justify-center">
                  <span className="font-bold">{getTotalChildCount(group)}</span>
                </div>
              </div>

              <div className="flex flex-col items-center mt-8 group cursor-pointer" onClick={() => setShowCaretakerExplanation(true)}>
                <h3 className="font-bold mb-2">Medewerkers
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-gray-300 group-hover:text-accent ml-2 mt-[6px] absolute text-sm"
                />
                </h3>
              </div>
              
              {!readOnly && (
                <select
                  className="w-full mt-2 p-2 border rounded"
                  value=""
                  onChange={(e) => {
                    if (e.target.value === 'temporary') {
                      addTemporaryCaretaker(group.id, 'unknown');
                    } else if (e.target.value === 'zzp-needed') {
                      addTemporaryCaretaker(group.id, 'zzp-needed');
                    } else if (e.target.value === 'zzp-confirmed') {
                      addTemporaryCaretaker(group.id, 'zzp-confirmed');
                    } else {
                      assignCaretaker(e.target.value, group.id);
                    }
                  }}
                  disabled={status === 'invalid'}
                >
                  <option value="">Inroosteren</option>
                  {unassignedCaretakers.map(caretaker => (
                    <option key={caretaker.id} value={caretaker.id}>
                      {caretaker.name}
                      {caretaker.availability !== 100 ? ` (${caretaker.availability}%)` : ''}
                    </option>
                  ))}
                  {unassignedCaretakers.length > 0 && (
                    <option disabled>──────────</option>
                  )}
                  <option value="temporary">Onbekend</option>
                  <option value="zzp-needed">ZZP nodig</option>
                  <option value="zzp-confirmed">ZZP bevestigd</option>
                </select>
              )}

              {(
                <Droppable droppableId={group.id} isDropDisabled={readOnly}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      id="assigned-caretakers"
                      className="mt-2 border rounded p-2 h-33 overflow-y-auto"
                    >
                      {sortedGroupCaretakers.map((caretaker, index) => (
                        <Draggable key={caretaker.id} draggableId={caretaker.id} index={index} isDragDisabled={readOnly}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...(readOnly ? {} : provided.dragHandleProps)}
                              className={`flex justify-between items-center mb-1 ${readOnly ? '' : 'cursor-pointer'}`}
                              onClick={() => !readOnly && setCaretakerToEdit({ groupId: group.id, caretaker })}
                            >
                              <span className={caretaker.isSick ? 'text-gray-400' : ''}>
                                {caretaker.name}
                                {caretaker.isSick ? ' (0%, ziek)' : caretaker.availability !== 100 ? ` (${caretaker.availability}%)` : ''}
                              </span>
                              {group.caretakerNotes && group.caretakerNotes[caretaker.id] && (
                                <span className="text-xs italic text-gray-500 ml-2 mr-auto bg-main-bg p-[4px] rounded">
                                  {group.caretakerNotes[caretaker.id].length > 20 
                                    ? group.caretakerNotes[caretaker.id].substring(0, 20) + '...' 
                                    : group.caretakerNotes[caretaker.id]}
                                </span>
                              )}
                              <div className="flex items-center">
                                {(canRemoveCaretaker(group, caretakers, caretaker)) && (
                                  <FontAwesomeIcon icon={faStreetView} className="text-overstaffed mr-2" />
                                )}
                                {readOnly && caretaker.isSick && (
                                  <FontAwesomeIcon icon={faHouseMedical} className="text-footer-bg mr-2" />
                                )}
                                {!readOnly && (
                                  <>
                                    <button 
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleCaretakerSickStatus(caretaker.id);
                                      }} 
                                      className={`mr-2 ${caretaker.isSick ? 'text-footer-bg' : 'text-gray-300'} hover:text-footer-bg`}
                                    >
                                      <FontAwesomeIcon icon={faHouseMedical} />
                                    </button>
                                    <button 
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        unassignCaretaker(caretaker.id, group.id);
                                      }} 
                                      className="text-gray-500 hover:text-red-500"
                                    >
                                      ×
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      {group.temporaryCaretakers && group.temporaryCaretakers.map((tempCaretaker) => (
                        <div key={tempCaretaker.id} className="flex justify-between items-center mb-1">
                          <span className="flex items-center cursor-pointer" onClick={() => setShowCaretakerExplanation(true)}>
                            {tempCaretaker.type === 'unknown' && (
                              <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />
                            )}
                            {tempCaretaker.type === 'zzp-needed' && (
                              <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 mr-2" />
                            )}
                            {tempCaretaker.type === 'zzp-confirmed' && (
                              <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
                            )}
                            {tempCaretaker.name}
                          </span>
                          {!readOnly && (
                            <div className="flex items-center">
                              {canRemoveCaretaker(group, caretakers, tempCaretaker) && (
                                <FontAwesomeIcon icon={faStreetView} className="text-overstaffed mr-2" />
                              )}
                              <button 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  unassignCaretaker(tempCaretaker.id, group.id);
                                }} 
                                className="text-gray-500 hover:text-red-500"
                              >
                                ×
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </Droppable>
              )}

              {status === 'invalid' && <div className="mt-4 text-understaffed font-bold">Ongeldige groep</div>}

              {(!readOnly || (readOnly && groupNotes[group.id])) && (
                <>
                  <h3 className="mt-8 font-bold">Notities</h3>
                  {!readOnly ? (
                    <textarea
                      id="group-notes"
                      className="w-full mt-4 p-2 border rounded"
                      rows={3}
                      placeholder="Notities..."
                      value={groupNotes[group.id] || ''}
                      onChange={(e) => handleUpdateGroupNotes(group.id, e.target.value)}
                    />
                  ) : (
                    <div 
                      id="group-notes" 
                      className="w-full mt-4 p-2 border rounded text-left whitespace-pre-wrap"
                    >
                      {groupNotes[group.id]}
                    </div>
                  )}
                </>
              )}

            </div>
          );
        })}
        {canAddGroup() && !readOnly && (
          <div className={`flex flex-col items-center ${isLandingPage ? 'w-60' : 'w-full md:w-80 lg:w-80'}`}>
              <div 
              onClick={handleAddGroup}
              className="w-full h-64 border-2 border-dashed border-gray-500 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-gray-500 print:hidden"
              >
              <span className="text-md font-bold text-gray-500">Voeg een groep toe</span>
              <span className="text-4xl text-gray-500">+</span>
              </div>
          </div>
        )}
        {!isLandingPage && (
          <div className="flex items-center justify-center w-full">
            <div className={`flex flex-col items-center ${isLandingPage ? 'w-60' : 'w-full max-w-3xl clear-both'}`}>
              <div className="w-full h-64 border-2 border-dashed border-gray-500 rounded-lg flex flex-col items-center justify-center print:hidden">
                <textarea
                  className="w-full h-full p-4 px-10 bg-transparent border-none resize-none text-left"
                  placeholder={readOnly ? "" : "Notities..."}
                  value={planningNotes}
                  onChange={(e) => !readOnly && handleUpdatePlanningNotes(e.target.value)}
                  readOnly={readOnly}
                />
              </div>
            </div>
          </div>
        )}
        {bkrInfoModalData && (
          <BKRInfoModal
            group={bkrInfoModalData}
            onClose={() => setBkrInfoModalData(null)}
          />
        )}
        {showAdjustmentExplanation && (
          <AdjustmentExplanationModal
            onClose={() => setShowAdjustmentExplanation(false)}
          />
        )}
        {showCaretakerExplanation && (
          <CaretakerExplanationModal
            onClose={() => setShowCaretakerExplanation(false)}
          />
        )}
        {caretakerToEdit && !readOnly && (
          <CaretakerNoteModal
            caretaker={caretakerToEdit.caretaker}
            note={groups.find(g => g.id === caretakerToEdit.groupId)?.caretakerNotes?.[caretakerToEdit.caretaker.id] || ''}
            groups={groups}
            onClose={() => setCaretakerToEdit(null)}
            onSave={(note: string) => {
              handleUpdateCaretakerNote(caretakerToEdit.groupId, caretakerToEdit.caretaker.id, note);
              setCaretakerToEdit(null);
            }}
            onMoveCaretaker={moveCaretaker}
          />
        )}
      </div>
    </DragDropContext>
  );
};

export default GroupView;