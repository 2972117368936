import React, { useRef, useMemo } from 'react';
import GroupView from './GroupView';

interface ExplanationLabel {
  text: string;
  fromSelector: string;
  toPosition: { top: string, left: string };
}

const ExplainedGroupView: React.FC<any> = (props) => {
  const groupRef = useRef<HTMLDivElement>(null);
  const explanations = useMemo<ExplanationLabel[]>(() => [
    {
      text: '2 medewerkers op 15 kinderen, volgens BKR',
      fromSelector: '#child-caretaker',
      toPosition: { top: '3%', left: '70%' },
    },
    {
      text: 'Er is nog ruimte voor één kind van 2 tot 4 in deze groep',
      fromSelector: '#adjustment-2-3',
      toPosition: { top: '25%', left: '70%' },
    },
    {
      text: 'Hier zie je de ingeplande medewerkers voor deze groep',
      fromSelector: '#assigned-caretakers',
      toPosition: { top: '55%', left: '70%' },
    },
    {
      text: 'Hier kun je notities over de groep maken',
      fromSelector: '#group-notes',
      toPosition: { top: '85%', left: '70%' },
    },
  ], []);

  return (
    <div className="relative" ref={groupRef}>
      <GroupView {...props} isLandingPage={true} />
      {explanations.map((explanation, index) => (
        <div
          key={index}
          className={`absolute bg-white border border-gray-300 rounded p-2 shadow-md text-sm explanation-${index}`}
          style={{ top: explanation.toPosition.top, left: explanation.toPosition.left }}
        >
          {explanation.text}
        </div>
      ))}
      
    </div>
  );
};

export default ExplainedGroupView;