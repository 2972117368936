import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faTimes } from '@fortawesome/free-solid-svg-icons';

interface CustomAlertProps {
  message: string;
  link?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ message, link, onConfirm, onCancel }) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (onCancel) {
          onCancel();
        } else {
          onConfirm();
        }
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onConfirm, onCancel]);

  const handleShare = async () => {
    if (link && 'share' in navigator) {
      try {
        await navigator.share({
          title: 'GroepsPlanner gedeelde link',
          text: 'Bekijk deze planning',
          url: link,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full shadow-lg max-h-[90vh] overflow-y-auto relative">
        <button onClick={onCancel || onConfirm} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <p className="text-lg mb-4">{message}</p>
        {link && (
          <div className="mb-4">
            <a href={link} target="_blank" rel="noopener noreferrer" className="text-accent hover:underline block break-all">
              {link}
            </a>
          </div>
        )}
        <div className="flex justify-between space-x-2">
          {onCancel && (
            <button
              onClick={onCancel}
              className="bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400 transition-colors flex-1"
            >
              Annuleren
            </button>
          )}
          {link && 'share' in navigator && (
            <button
              onClick={handleShare}
              className="bg-accent text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors flex items-center justify-center flex-1"
            >
              <FontAwesomeIcon icon={faShare} className="mr-2" />
              Deel link
            </button>
          )}
          <button
            onClick={onConfirm}
            className="bg-accent text-white py-2 px-4 rounded hover:bg-opacity-90 transition-colors flex-1"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;