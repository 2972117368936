import React, { useState, KeyboardEvent, useEffect } from 'react';
import { Caretaker, Group } from '../Types';

interface CaretakerNoteModalProps {
  caretaker: Caretaker;
  note: string;
  groups: Group[];
  onClose: () => void;
  onSave: (note: string) => void;
  onMoveCaretaker: (caretakerId: string, newGroupId: string, newIndex: number) => void;
}

const CaretakerNoteModal: React.FC<CaretakerNoteModalProps> = ({ 
  caretaker, 
  note, 
  groups, 
  onClose, 
  onSave, 
  onMoveCaretaker 
}) => {
  const [currentNote, setCurrentNote] = useState(note);
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc as any);

    return () => {
      document.removeEventListener('keydown', handleEsc as any);
    };
  }, [onClose]);

  const handleSave = () => {
    onSave(currentNote.trim().length === 0 ? '' : currentNote);
    onClose();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    } 
  };

  const handleMove = () => {
    if (selectedGroupId) {
      onMoveCaretaker(caretaker.id, selectedGroupId, 0);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-4 flex flex-col items-center text-center">
          Verplaats {caretaker.name}
        </h2>
        <select
          className="w-full p-2 border rounded mb-4"
          value={selectedGroupId}
          onChange={(e) => setSelectedGroupId(e.target.value)}
        >
          <option value="">Selecteer een groep</option>
          {groups
            .filter(group => !group.caretakerIds.includes(caretaker.id))
            .map(group => (
              <option key={group.id} value={group.id}>
                {group.title}
              </option>
            ))}
        </select>
        <button
          onClick={handleMove}
          disabled={!selectedGroupId}
          className={`w-full px-4 py-2 rounded ${
            selectedGroupId
              ? 'bg-accent text-white hover:bg-opacity-90'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
        >
          Verplaats
        </button>
        <hr className="border-t border-gray-300 my-4 w-1/4 max-w-[100px] mx-auto" />
        <h2 className="text-2xl font-bold mb-4 flex flex-col items-center text-center">
          Notitie voor {caretaker.name}
        </h2>
        <input
          type="text"
          value={currentNote}
          onChange={(e) => setCurrentNote(e.target.value)}
          onKeyDown={handleKeyDown}
          className="w-full p-2 border rounded mb-4"
          placeholder="Notitie..."
          autoFocus
        />
        <div className="flex justify-center mb-4">
          <button onClick={onClose} className="flex-1 mr-2 px-4 py-2 bg-gray-200 rounded">Annuleren</button>
          <button onClick={handleSave} className="flex-1 px-4 py-2 bg-accent text-white rounded">Opslaan</button>
        </div>
      </div>
    </div>
  );
};

export default CaretakerNoteModal;